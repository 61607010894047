import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { CursosService } from "src/app/service/cursos.service";
import { MaterialService } from "src/app/service/material.service";
import { PublicacionService } from "src/app/service/publicacion.service";
import { Calendario, mapearResponseACalendario } from "src/app/interface/calendario";
import { CommonModule } from "@angular/common";
import { Publicacion, mapearResponsePublicacion,} from "src/app/interface/publicacion";
import { Material, mapearResponseMaterial } from "src/app/interface/material";
import { FechasService } from "src/app/service/util/fechas.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PaginacionComponent } from "../../common/paginacion/paginacion.component";
import { PaginatePipe } from "../../../pipes/paginate.pipe";
import { saveAs } from 'file-saver';
import { HttpClient } from "@angular/common/http";

const iconosMateriales: { [key: string]: string } = {
    'Libros': 'bx bxs-book',
    'Código': 'bx bx-code-block',
    'Presentaciones': 'bx bx-slideshow',
    'Base de Datos': 'bx bx-data',
    'Herramientas': 'bx bxs-wrench'
};

interface Archivo {
    file: File;
    url?: string; // URL de vista previa para imágenes
  }

@Component({
    selector: "app-detalles-curso-page",
    standalone: true,
    templateUrl: "./detalles-curso-page.component.html",
    styleUrls: ["./detalles-curso-page.component.scss"],
    providers: [BsModalService],
    imports: [
        HeaderStyleOneComponent,
        RouterLink,
        SidebarMenuComponent,
        CommonModule,
        FormsModule,
        ToastrModule,
        ReactiveFormsModule,
        NgbModule,
        PaginacionComponent,
        PaginatePipe
    ]
})
export class DetallesCursoPageComponent implements OnInit {
    private idCalendario: number;
    rolUsuario: string;
    calendario: Calendario;
    publicaciones: Publicacion[];
    materiales: Material[]
    isToggled = false;
    idUsuario: any;
    nombre: string;
    descripcion: string;
    archivos: Archivo[] = [];
    idPublicacion: any;
    private modalRef: BsModalRef
    private modalRefNuevoComentario: BsModalRef
    modificar: boolean = false
    formPublic: FormGroup
    nombrePublicacion: any
    descripcionPublicacion: any
    comentarios: any
    openAccordionIndex: number = -1;
    textoComentario: string;
    mostrarFormulario: number = -1
    isMobile: boolean;
    currentPage: number = 1;
    pageSize: number = 3;
    fechaHoy: string;

    constructor(
        public themeService: ThemeCustomizerService,
        public dateService:FechasService,
        private router: Router,
        private cursosService: CursosService,
        private materialService: MaterialService,
        private publicacionService: PublicacionService,
        activatedRoute: ActivatedRoute, public modalService: BsModalService,
        public toastrService: ToastrService, private fechasService: FechasService, private http: HttpClient
    ) {
        this.themeService.isToggled$.subscribe((isToggled) => {
            this.isToggled = isToggled;
        });
        activatedRoute.params.subscribe((params) => {
            this.idCalendario = params.idcalendario;
            this.idPublicacion = params['idPublicacion'];
        });
        this.rolUsuario = localStorage.getItem("rolUsuario");
        this.idUsuario = localStorage.getItem('idUsuario');
        this.isMobile = window.innerWidth < 767;
        window.onresize = () => {
          this.isMobile = window.innerWidth < 767;
          
        };
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        this.obtenerDetallesCalendario();
        this.obtenerMaterialCurso();
        this.obtenerPublicacionesCurso();
        this.obtenerFechaHoy()
    }

    private async obtenerDetallesCalendario() {
        const response = await this.cursosService.detallesCalendario(this.idCalendario);
        this.calendario = mapearResponseACalendario(response);
    }
    private async obtenerMaterialCurso() {
        const response = await this.materialService.materialCompartido(this.idCalendario);
        const data: any[] = [response];
        this.materiales = data[0].map((d: any) => mapearResponseMaterial(d));
        this.materiales.forEach(material => {
            material.icono = iconosMateriales[material.tipoMaterial] || '';
        })
        console.log(this.materiales, 'mats')
    }

    private async obtenerPublicacionesCurso() {
        const response = await this.publicacionService.obtenerPublicaciones(this.idCalendario);
        console.log(response, 'res')
        const data: any[] = [response];
        this.publicaciones = data[0].map((d: any) =>
            mapearResponsePublicacion(d)
        );
        console.log('publicaciones', this.publicaciones)
    }

    redirigirAsistencia(calendario: Calendario){
        this.router.navigate(['/asistencia', calendario.idCalendario, calendario.curso]);
    }

    onFileSelected(event: any) {
        const selectedFiles: FileList = event.target.files;
        for (let i = 0; i < selectedFiles.length; i++) {
          const file: File = selectedFiles[i];
          const archivo: Archivo = { file: file };

          // Verificar si es una imagen para mostrar una vista previa
          if (this.esImagen(file)) {
            const reader = new FileReader();
            reader.onload = (e: any) => archivo.url = e.target.result;
            reader.readAsDataURL(file);
          }

          this.archivos.push(archivo);
        }
      }

      esImagen(archivo: any): boolean {
        return archivo.type ? archivo.type.startsWith('image') : false;
    }

    esImagenMostrar(url: string): boolean {
      const extension = url.split('.').pop();
      return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
  }


      eliminarArchivo(index: number) {
        this.archivos.splice(index, 1);
      }

      guardarNuevaPublicacion(form: any) {
        if (form.invalid) {
           this.toastrService.error('Completa los campos requeridos', 'Error');
          return;
        }

        const formData = new FormData();
        if (this.archivos.length == 0) {
          formData.append('users', this.idUsuario);
          formData.append('calendario', this.idCalendario.toString());
          formData.append('nombrePublicacion', this.nombre);
          formData.append('texto', this.descripcion);
          //mandar petición a la API sin archivo
        } else {
          formData.append('users', this.idUsuario);
          formData.append('calendario', this.idCalendario.toString());
          formData.append('nombrePublicacion', this.nombre);
          formData.append('texto', this.descripcion);
          //mandar petición a la API con archivo
          /* Archivos multiples */
          for (let i = 0; i < this.archivos.length; i++) {
            formData.append('files[]', this.archivos[i].file);
          }
        }
        if (this.modificar) { formData.append('idPublicacion', this.idPublicacion); }
             // Verificación del contenido del FormData
             formData.forEach((value, key) => {
              console.log(key, value);
          });
        this.publicacionService.registrarTarea(formData).subscribe(
            (resultado) => {
               this.toastrService.success('Publicacion guardada correctamente', 'Éxito');
               form.resetForm(); // Reset the form
               this.obtenerPublicacionesCurso()
              this.cerrarModal();
            },
            (error) => {
               this.toastrService.error('Ocurrió un error al guardar la publicacion', 'Error');
               console.log(error)
            }
          );
      }

        // Eliminar una publicacion
  eliminarTarea(idPublicacion:number){
    this.publicacionService.eliminarTarea(idPublicacion).subscribe((data:any) => {
      if (data == true) {
        this.obtenerPublicacionesCurso()
        this.toastrService.success("Publicacion eliminada correctamente", "Exito")
      }else{
        this.toastrService.error("La publicacion no se elimino", "Error")
      }
    },error=>{
      this.toastrService.error("Error al eliminar la publicacion", "Error")
    });

  }


      abrirModalNuevaPublicacion(nuevaPublicacion: any, datos: any) {
        this.inicializarModal(datos)
        if(datos !== null){
          this.modificar = true
          this.idPublicacion = datos.idPublicacion
        }
        this.modalRef = this.modalService.show(nuevaPublicacion, { backdrop:'static'});
      }


      mostrarFormDetalles(datos: any, index: any) {
        this.nombrePublicacion = datos.nombre
        this.descripcionPublicacion = datos.texto
        this.idPublicacion = datos.idPublicacion

        // this.modalRef = this.modalService.show(verDetalles, {class: 'modal-lg', backdrop: 'static'});
        this.obtenerComentarios(datos.idPublicacion)
        this.mostrarFormulario = index
      }

      ocultarComentarios(index: any) {
        if (this.mostrarFormulario === index) {
          this.mostrarFormulario = null; // Ocultar el formulario
        }
      }


      abrirModalNuevoComentario(nuevoComentario: any) {
        this.modalRefNuevoComentario = this.modalService.show(nuevoComentario, { backdrop:'static'});
      }

      inicializarModal(datos: any) {
        if (datos) {
            this.nombre = datos.nombre || '';
            this.descripcion = datos.texto || '';
        } else {
            this.nombre = '';
            this.descripcion = '';
        }
    }

      // Metodo para obtener todoas las publicaciones del curso
  obtenerComentarios(idPublicacion) {
    this.publicacionService.obtenerComentarios(idPublicacion).subscribe((comentarios: any) => {
      this.comentarios = comentarios[0]
      console.log(comentarios, 'comentarios')
    }, error => {
      console.log(error)
    });
  }


  guardarComentario(form: any) {
    if (form.invalid) {
      this.toastrService.error('Completa los campos requeridos', 'Error');
    }else{
      let formData: FormData = new FormData();
      if(this.archivos.length == 0) {
        formData.append('publicacionusers', this.idPublicacion);
        formData.append('comentario', this.textoComentario);
        formData.append('users', localStorage.getItem('idUsuario'));

      }else{
        formData.append('publicacionusers', this.idPublicacion);
        formData.append('comentario', this.textoComentario);
        formData.append('users', localStorage.getItem('idUsuario'));

       for (let i = 0; i < this.archivos.length; i++) {
        formData.append('file', this.archivos[i].file);
      }
      }

      this.publicacionService.registrarComentario(formData).subscribe ((data: any) => {
        this.toastrService.success('Comentario guardado correctamente', 'Éxito');
        this.textoComentario = ''
        this.archivos = []
        form.resetForm(); // Reset the form
        this.obtenerComentarios(this.idPublicacion)
      },error => {
        console.log(error)
        this.toastrService.error('Error al guardar el comentario', 'Error');
      });
    }
  }

  descartarComentario(){
    this.textoComentario = ''
    this.archivos = []
  }


  toggleAccordion(index: number) {
    console.log('index fuera', index)
    if (this.openAccordionIndex === index) {
        this.openAccordionIndex = -1;
    } else {
        this.openAccordionIndex = index;
        console.log('index dentro', this.openAccordionIndex)
    }
}

  formatearFechaConHora(fechaHora: string): string {
    return this.fechasService.formatearFechaConHora(fechaHora)
}

      cerrarModal() {
        if (this.modalRef) {
          this.modalRef.hide();
          this.nombre = '';
          this.descripcion = '';
          this.archivos = [];
        }
      }

      cerrarModalNuevoComentario() {
        if (this.modalRefNuevoComentario) {
          this.modalRefNuevoComentario.hide();
          this.textoComentario = '';
          this.archivos = [];
        }
      }

      obtenerFechaHoy(): void {
        const hoy = new Date();
        const dia = String(hoy.getDate()).padStart(2, '0');
        const mes = String(hoy.getMonth() + 1).padStart(2, '0');
        const anio = hoy.getFullYear();
        this.fechaHoy = `${dia}/${mes}/${anio}`;
      }

 onPageChange(newPage: number) {
    this.currentPage = newPage;
  }
}
