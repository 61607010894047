import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { PaginacionComponent } from "../../../common/paginacion/paginacion.component";
import { PaginatePipe } from "../../../../pipes/paginate.pipe";
import { BsModalService } from 'ngx-bootstrap/modal';
import { CursosService } from 'src/app/service/cursos.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuizCategory } from 'src/app/interface/categoria';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-examenes-admin',
  standalone: true,
  imports: [CommonModule, PaginacionComponent, PaginatePipe, FormsModule, ReactiveFormsModule],
  templateUrl: './examenes-admin.component.html',
  styleUrl: './examenes-admin.component.scss',
  providers:[BsModalService, ToastrService]
})
export class ExamenesAdminComponent implements OnInit{

  @Input() columns: { key: string, title: string, accordionKey?:boolean }[] = []; // Recibe columnas dinámicamente
  @Input() data: any[] = []; // Recibe data dinámica desde el componente padre
  currentPage: number = 1;
  pageSize: number = 10;
  @Input() title: string;
  @Input() buttonText: string;
  filteredData: any[] = []; // Nueva propiedad para los datos filtrados
  @Output() onPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onPrimaryAction: EventEmitter<number> = new EventEmitter<any>();
  @Output() onTertiaryAction: EventEmitter<number> = new EventEmitter<any>();
  @Output() onQuaternaryAction: EventEmitter<number> = new EventEmitter<any>();
  @Output() onSecondaryAction: EventEmitter<number> = new EventEmitter<any>();
  @Output() onOpenModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMenuToggle: EventEmitter<boolean> = new EventEmitter<boolean>(); // Evento para el menú
  isMenuOpen: { [key: number]: boolean } = {};
  isMobile:boolean

  constructor(){
    this.isMobile = window.innerWidth < 768;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 768;
    };
  }

  ngOnInit(): void {
    this.data.forEach(item => {
      this.isMenuOpen[item.id] = false; // O usa un identificador único
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      // Cuando data cambie, inicializa filteredData y isMenuOpen
      this.filteredData = [...this.data];
      this.data.forEach(item => {
        this.isMenuOpen[item.id] = false;
      });
    }
  }

 toggleExpand(diploma: any) {
  diploma.isExpanded = !diploma.isExpanded;
}

 toggleMenu(itemId: number, event: MouseEvent, index: number) {
  event.stopPropagation(); // Evita que el evento cierre inmediatamente el menú
  const menuElement = document.querySelector(`#dropdown-menu-${itemId}`);

  if (menuElement) {
    const rect = menuElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Si el menú está demasiado cerca del borde inferior, ajusta su posición
    if (rect.bottom > windowHeight) {
      menuElement.classList.add('dropdown-menu-top'); // Agrega clase para mostrar el menú hacia arriba
    } else {
      menuElement.classList.remove('dropdown-menu-top');
    }
  }

  const secondMenuElement = document.getElementById(`dropdown-menu-${index}`)
  if(secondMenuElement){
    const currentPageSize = this.getCurrentPageSize();
    if (index + 1 == this.pageSize || currentPageSize - 1 === index ) {
        secondMenuElement.classList.add('dropdown-menu-top'); // Agrega clase para mostrar el menú hacia arriba
    } else {
        secondMenuElement.classList.remove('dropdown-menu-top');
    }
  }

  // Cierra los demás menús
  Object.keys(this.isMenuOpen).forEach(key => {
    if (+key !== itemId) {
      this.isMenuOpen[key] = false;
    }
  });

  // Alterna el menú del item seleccionado
  this.isMenuOpen[itemId] = !this.isMenuOpen[itemId];
}

getCurrentPageSize(): number {
  const totalRecords = this.data.length;  // El total de registros de la tabla
  const pageIndex = this.currentPage;     // Índice de la página actual (0, 1, 2, etc.)
  const pageSize = this.pageSize;         // Tamaño de la página (en este caso 10)

  // Cálculo de la cantidad de registros en la página actual
  const startIndex = pageIndex * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalRecords);

  return pageSize + (endIndex - startIndex);
}




  closeMenu(itemId: number) {
    this.isMenuOpen[itemId] = false; // Cierra el menú al salir
  }

  primaryAction(param: any) {
    this.onPrimaryAction.emit(param);
  }

  secondaryAction(param: any) {
    this.onSecondaryAction.emit(param);
  }

  tertiaryAction(param: any) {
    this.onTertiaryAction.emit(param);
  }

  quaternaryAction(param: any) {
    this.onQuaternaryAction.emit(param);
  }

  onEdit(item: any) {
    this.onOpenModal.emit(item);
  }

  filterChange(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
  
    // Filtrar los datos
    this.filteredData = this.data.filter(item =>
      Object.values(item).some(val =>
        String(val).toLowerCase().includes(filterValue)
      )
    );
  
    this.currentPage = 1; // Reiniciar a la primera página después del filtrado
  }

  getAccordionColumn() {
    // Buscar la columna que tenga la propiedad accordionKey en true
    return this.columns.find(column => column.accordionKey);
  }
  

  changePageSize(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
  }

  changePage(newPage: number) {
    this.currentPage = newPage;
  }
}
