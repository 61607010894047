import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModalPreguntaService {
  questionTypeValueSelected:any
  fieldNameCreados:any[]
  currentTotalCorrectAnswers: any;

  totalOptionsAndAnswers = [{
    id: 1,
    label: 1
  },
  {
    id: 2,
    label: 2
  },
  {
    id: 3,
    label: 3
  },
  {
    id: 4,
    label: 4
  },
  {
    id: 5,
    label: 5
  },
  {
    id: 6,
    label: 6
  },
  {
    id: 7,
    label: 7
  },
  {
    id: 8,
    label: 8
  },
  {
    id: 9,
    label: 9
  }

  ]

  typeQuestionOptions = [{
    id: 'radio',
    label: 'Respuesta simple'
  },
  {
    id: 'checkbox',
    label: 'Opcion multiple'
  },
  // {
  //   id: 'blanks',
  //   label: 'Completar frase'
  // },
  // {
  //   id: 'match',
  //   label: 'Unir preguntas con respuesta'
  // },
  // {
  //   id: 'para',
  //   label: 'Parrafo'
  // },
  // {
  //   id: 'video',
  //   label: 'Video'
  // },
  // {
  //   id: 'audio',
  //   label: 'Audio'
  // }
]

  //MODAL AGREGAR PREGUNTA
  difficultyLevelOptions = [{
    id: 'easy',
    label: 'Facil'
  },
  {
    id: 'medium',
    label: 'Media'
  },
  {
    id: 'hard',
    label: 'Dificil'
  }]

  constructor() { }

interpretarCamposACrear(questionType:string, optionsLength:number, nameFieldCreados:any[], modalFields:any[],
  valueOptions:any[], correctAnswersValue:string){
  // Añadir campos según el tipo de pregunta seleccionada
  this.questionTypeValueSelected = questionType;
  this.fieldNameCreados = [...nameFieldCreados]
   if (this.fieldNameCreados.includes('totalOptions') && this.fieldNameCreados.length === 1) {
    if (this.questionTypeValueSelected === 'radio') {
      this.generateOptionsWithImage(optionsLength, modalFields, valueOptions, correctAnswersValue).subscribe(
        (resultModalFields: any[] = []) => {
          modalFields = [ ...resultModalFields];
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );

    } else if (this.questionTypeValueSelected === 'checkbox') {
      this.generateOptionsWithMultipleAnswers(optionsLength, modalFields, valueOptions, correctAnswersValue).subscribe(
        (resultModalFields: any[] = []) => {
          modalFields = [ ...resultModalFields];
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );
      this.generateCorrectAnswerFields(this.currentTotalCorrectAnswers, modalFields, correctAnswersValue).subscribe(
        (resultModalFields: any[] = []) => {
          modalFields = [ ...resultModalFields];
        },
        (error: any) => {
          console.error('Error generando respuestas correctas', error);
        }
      );
    } else if (this.questionTypeValueSelected === 'match') {
      this.generateMatchQuestions(optionsLength, modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          modalFields = [...modalFields, ...resultModalFields];
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );
    } else if (this.questionTypeValueSelected === 'para' || this.questionTypeValueSelected === 'video'||
      this.questionTypeValueSelected === 'audio') {
      console.log('entro en totaloptions')
      this.generateQuestionsAndOptions(optionsLength, 'options', modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          modalFields = [...modalFields, ...resultModalFields];
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );
    }
  }
  else if (this.fieldNameCreados.includes('totalQuestions') && this.fieldNameCreados.length === 1) {
    if (this.questionTypeValueSelected === 'para' || this.questionTypeValueSelected === 'video'||
      this.questionTypeValueSelected === 'audio') {
      console.log('entro en totalquestions')
      this.generateQuestionsAndOptions(optionsLength, 'questions', modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          modalFields = [...modalFields, ...resultModalFields];
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );
    }
  }
  else if (this.fieldNameCreados.includes('totalWhiteAnswers')) {
    this.generateWhiteAnswers(optionsLength, modalFields).subscribe(
      (resultModalFields: any[] = []) => {
        modalFields = [...modalFields, ...resultModalFields];
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }
  return of(modalFields);
}

//GENERAR CAMPO DE TOTAL DE OPCIONES
generateTotalOptions(newModalFields:any[], value?:number | null){
  newModalFields.push({
    name: 'totalOptions',
    type: 'select',
    label: 'Total de opciones*',
    required: true,
    dynamic: false,
    errorMessage: 'El total de opciones es obligatorio.',
    value: value? value : null,
    change: true,
    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
});
}

//GENERAR CAMPO DE TOTAL DE RESPUESTAS EN BLANCO
generateTotalWhiteAnswers(newModalFields:any[], value?:number | null){
  newModalFields.push({
    name: 'totalWhiteAnswers',
    type: 'select',
    label: 'Total de respuestas en blanco*',
    required: true,
    dynamic: false,
    value: value? value : null,
    errorMessage: 'Este campo es obligatorio.',
    change: true,
    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
});
}

generateTotalOptionsWithAnswers(newModalFields:any[], value?:number | null){
  newModalFields.push({
    name: 'totalOptions',
    type: 'select',
    label: 'Total de opciones*',
    required: true,
    dynamic: false,
    errorMessage: 'El total de opciones es obligatorio.',
    value: value? value : null,
    change: true,
    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
});
}

generateTotalQuestionsAndTotalOptions(newModalFields:any[]){
  newModalFields.push({
    name: 'totalQuestions',
    type: 'select',
    label: 'Total de preguntas*',
    required: true,
    dynamic: false,
    errorMessage: 'El total de preguntas es obligatorio.',
    change: true,
    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
});
newModalFields.push({
    name: 'totalOptions',
    type: 'select',
    label: 'Total de opciones*',
    required: true,
    dynamic: false,
    errorMessage: 'El total de opciones es obligatorio.',
    change: true,
    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
});
}

generateQuestionsAndOptions(selectedType: any, field: string, modalFields:any[]) {
  // Almacenar temporalmente las opciones existentes si ya han sido generadas
  const existingOptions = modalFields.filter(f => f.relatedField === 'totalOptions');

  // Limpiar los campos según el tipo (preguntas u opciones)
  if (field === 'questions') {
      // Limpiar solo los campos relacionados a preguntas
      modalFields = modalFields.filter(f => f.relatedField !== 'totalQuestions');
      modalFields = modalFields.filter(f => f.relatedField !== 'totalOptions');

      // Generar los campos de preguntas y respuestas
      for (let i = 1; i <= selectedType; i++) {
          // Insertar los campos de pregunta y respuesta en el orden correcto
          modalFields.push({
              name: `question${i}`,
              type: 'text',
              label: `Pregunta ${i}`,
              dynamic: true,
              relatedField: 'totalQuestions',
              required: true,
              unique: true,
              errorMessage: `La pregunta ${i} es obligatoria.`
          });

          // Insertar las opciones entre la pregunta y la respuesta
          existingOptions.forEach(option => {
              modalFields.push({
                  ...option,
                  name: `option${option.name.match(/\d+/)[0]}`,  // Mantener el número de la opción
                  label: `Opción ${option.name.match(/\d+/)[0]}`, // Actualizar el label con el número de la opción
                  relatedField: 'totalOptions',
              });
          });

          // Agregar el campo de respuesta
          modalFields.push({
              name: `answer${i}`,
              type: 'text',
              label: `Respuesta ${i}`,
              dynamic: true,
              relatedField: 'totalQuestions',
              required: true,
              errorMessage: `La respuesta ${i} es obligatoria.`
          });
      }
  } else if (field === 'options') {
      // Limpiar solo los campos relacionados a opciones
      modalFields = modalFields.filter(f => f.relatedField !== 'totalOptions');

      // Encontrar los campos de preguntas y respuestas ya generados para insertar las opciones entre ellos
      let newModalFields = [];

      modalFields.forEach(field => {
          newModalFields.push(field); // Insertar cada campo original

          // Si el campo es una pregunta, añadir las opciones correspondientes después de esa pregunta (antes de la respuesta)
          if (field.unique) {
              for (let j = 1; j <= selectedType; j++) {
                  newModalFields.push({
                      name: `option${j}`,
                      type: 'text',
                      label: `Opción ${j}`,
                      dynamic: true,
                      relatedField: 'totalOptions',
                      required: true,
                      errorMessage: `La opción ${j} es obligatoria.`
                  });
              }
          }
      });

      // Actualizar modalFields con los nuevos campos
      modalFields = newModalFields;

  }
  return of(modalFields);
}

generateOptionsWithImage(selectedType: any, modalFields: any[], valueOptions?: any[] | null,
  correctAnswersValue?:string) {
  // Limpiar campos de opciones existentes
  modalFields = modalFields.filter(field => field.relatedField !== 'totalOptions');

  for (let i = 1; i <= selectedType; i++) {
    // Verificar si se pasó un arreglo de opciones y obtener los valores para la opción y la imagen
    const optionValue = valueOptions && valueOptions[i - 1] ? valueOptions[i - 1].option_value : null;
    const imageValue = valueOptions && valueOptions[i - 1] ? valueOptions[i - 1].file_name : null;
    console.log(optionValue, imageValue, 'valores')
    // Generar campo de texto para la opción
    modalFields.push({
      name: `option${i}`,
      type: 'text',
      label: `Opción ${i}*`,
      characterLimit: 100,
      dynamic: true,
      relatedField: 'totalOptions',
      value: optionValue ? optionValue : null,  // Asignar el valor de la opción si existe
      required: true,
      errorMessage: `La opción ${i} es obligatoria.`
    });

    // Generar campo de archivo para la imagen
    modalFields.push({
      name: `optionImage${i}`,
      type: 'file',
      label: `Imagen ${i}`,
      dynamic: true,
      relatedField: 'totalOptions',
      required: false,
      value:  imageValue? `${environment.urlImagenesPreguntas}${imageValue}` : null,  // Asignar el valor de la imagen si existe
      errorMessage: `La imagen ${i} es obligatoria.`
    });
  }

  // Agregar el campo "Número de respuesta"
  modalFields.push({
    name: `answerNumber`,
    type: 'number',
    label: `Número de respuesta*`,
    dynamic: true,
    relatedField: 'totalOptions',
    characterLimit: 1,
    required: true,
    value: correctAnswersValue,
    errorMessage: `El número de respuesta es obligatorio.`
  });

  return of(modalFields);
}


generateOptionsWithMultipleAnswers(
  totalOptions: number,
  modalFields: any[],
  valueOptions?: any[] | null,
  correctAnswersValue?: string,
  newModalFields?: any[]
) {
  // Inicializamos currentFields con newModalFields si está presente, sino modalFields
  let currentFields: any[] = newModalFields ? newModalFields : modalFields;
  // Generar nuevos campos de opciones con imágenes
  for (let i = 1; i <= totalOptions; i++) {
    const optionValue = valueOptions && valueOptions[i - 1] ? valueOptions[i - 1].option_value : null;
    const imageValue = valueOptions && valueOptions[i - 1] ? valueOptions[i - 1].file_name : null;
 currentFields.push({
      name: `option${i}`,
      type: 'text',
      label: `Opción ${i}*`,
      dynamic: true,
      relatedField: 'totalOptions',
      characterLimit: 100,
      value: optionValue ? optionValue : null,
      required: true,
      errorMessage: `La opción ${i} es obligatoria.`
    });

    currentFields.push({
      name: `optionImage${i}`,
      type: 'file',
      label: `Imagen ${i}`,
      dynamic: true,
      relatedField: 'totalOptions',
      required: false,
      value: imageValue? `${environment.urlImagenesPreguntas}${imageValue}` : null,
      errorMessage: `La imagen ${i} es obligatoria.`
    });

    }


  // Parsear correctAnswersValue si tiene formato JSON
  let correctAnswersArray = [];
  let correctAnswersNumberValue
  if (this.countDigits(correctAnswersValue) > 1) {
    try {
      correctAnswersArray = JSON.parse(correctAnswersValue);
    } catch (e) {
      console.error("Error al parsear correctAnswersValue: ", e);
    }
  }else{
    correctAnswersNumberValue = parseInt(correctAnswersValue)
  }

  // Obtener el total de respuestas correctas
  const totalCorrectAnswers = correctAnswersArray.length > 0 ?correctAnswersArray.length : correctAnswersNumberValue;
  this.currentTotalCorrectAnswers = totalCorrectAnswers;

  // Agregar el campo "Total de respuestas correctas"
  currentFields.push({
    name: `correctAnswersNumber`,
    type: 'select',
    label: `Total de respuestas correctas*`,
    dynamic: true,
    value: totalCorrectAnswers ? totalCorrectAnswers : null,  // Seteamos el valor al total de respuestas correctas
    required: true,
    errorMessage: `El total de respuestas correctas es obligatorio.`,
    change: true,  // Habilitamos el evento change
    options: this.totalOptionsAndAnswers.map(option => ({ value: option.id, label: option.label }))
  });
  console.log('entro 1')


  // Lógica para retornar modalFields concatenado con currentFields
  if (newModalFields) {
    // Encontrar la posición del campo 'totalOptions' en modalFields
    const totalOptionsIndex = modalFields.findIndex(field => field.relatedField === 'totalOptions');
    // Encontrar la posición del campo 'correctAnswersNumber' en modalFields
    const correctAnswersNumberIndex = modalFields.findIndex(field => field.name === 'correctAnswersNumber');

    // Concatenar modalFields hasta 'totalOptions', luego currentFields, y luego los campos después de 'correctAnswersNumber'
    const combinedFields = [
      ...modalFields.slice(0, totalOptionsIndex),  // Campos hasta 'totalOptions'
      ...currentFields,                               // Agregar currentFields
      ...modalFields.slice(correctAnswersNumberIndex + 1)  // Campos después de 'correctAnswersNumber'
    ];

    return of(combinedFields); // Retornar los campos combinados
  } else {
    return of(currentFields); // Si no hay newModalFields, devolver solo modalFields
  }
}

countDigits(value: any): number {
  // Convertir a cadena y eliminar todo lo que no sea un dígito (incluye signos, puntos decimales, etc.)
  if(value === null || value === undefined){
    return 0;
  }
  const digitString = value.toString()
  return digitString.length; // Retorna la cantidad de dígitos
}

generateCorrectAnswerFields(totalCorrectAnswers: number, modalFields: any[], answers?: string) {
  // Limpiar los campos de respuestas correctas anteriores
  modalFields = modalFields.filter(field => field.relatedField !== 'correctAnswers');
  let parsedAnswers: { answer: string }[] = [];
  let valueIndividual
  if(this.countDigits(answers) > 1){
 // Parsear el string 'answers' a un array de objetos
 if(answers !== undefined){
   try {
     parsedAnswers = JSON.parse(answers);
   } catch (error) {
     console.error('Error al parsear el JSON de answers:', error);
   }
 }
  }else{
    valueIndividual = parseInt(answers)
  }


  // Generar nuevos campos para respuestas correctas con valores asignados
  for (let i = 1; i <= totalCorrectAnswers; i++) {
    // Tomar el valor de la respuesta correspondiente si existe en el array
    const answerValue = parsedAnswers[i - 1] ? parsedAnswers[i - 1].answer : valueIndividual;

    modalFields.push({
      name: `correctAnswer${i}`,
      type: 'number',
      label: `Respuesta correcta ${i}*`,
      dynamic: true,
      characterLimit: 1,
      placeholder: 'Ingrese el numero de respuesta correcta',
      relatedField: 'correctAnswers',
      required: true,
      value: answerValue? answerValue : null,  // Asignar el valor de la respuesta correcta si existe
      errorMessage: `La respuesta correcta ${i} es obligatoria.`
    });
  }
  this.currentTotalCorrectAnswers = totalCorrectAnswers;

  // Agregar el campo "Total de respuestas correctas"
  modalFields.forEach(field =>{
    if(field.name === 'correctAnswersNumber'){
      field.value = totalCorrectAnswers ? totalCorrectAnswers : null
    }
  });
  console.log('entro 2')
  return of(modalFields);  // Devuelve el nuevo array de campos
}



generateMatchQuestions(totalOptions: number, modalFields:any[]) {
  // Limpiar campos de opciones existentes
  modalFields = modalFields.filter(field => field.relatedField !== 'totalOptions');

  // Generar nuevos campos de emparejamiento
  modalFields.push({
      name: `leftTitle`,
      type: 'text',
      label: `Título de la izquierda`,
      dynamic: true,
      relatedField: 'totalOptions',
      required: true,
      errorMessage: `El título de la izquierda es obligatorio.`
  });
  modalFields.push({
      name: `rightTitle`,
      type: 'text',
      label: `Título de la derecha`,
      dynamic: true,
      relatedField: 'totalOptions',
      required: true,
      errorMessage: `El título de la derecha es obligatorio.`
  });

  for (let i = 1; i <= totalOptions; i++) {
      modalFields.push({
          name: `leftOption${i}`,
          type: 'text',
          label: `Opción de la izquierda ${i}`,
          dynamic: true,
          relatedField: 'totalOptions',
          required: true,
          errorMessage: `La opción de la izquierda ${i} es obligatoria.`
      });
      modalFields.push({
          name: `rightOption${i}`,
          type: 'text',
          label: `Opción de la derecha ${i}`,
          dynamic: true,
          relatedField: 'totalOptions',
          required: true,
          errorMessage: `La opción de la derecha ${i} es obligatoria.`
      });
      modalFields.push({
          name: `answer${i}`,
          type: 'text',
          label: `Respuesta ${i}`,
          dynamic: true,
          relatedField: 'totalOptions',
          required: true,
          errorMessage: `La respuesta ${i} es obligatoria.`
      });
  }
  return of(modalFields);
}


generateWhiteAnswers(selectedType:any, modalFields:any[]){
  // Limpiar campos de respuestas en blanco existentes
  const existingWhiteAnswers = modalFields.filter(field => field.relatedField === 'totalWhiteAnswers');
  if (existingWhiteAnswers.length === 0) {
      for (let i = 1; i <= selectedType; i++) {
          modalFields.push({
              name: `whiteAnswer${i}`,
              type: 'text',
              label: `Respuesta en blanco ${i}`,
              dynamic: true,
              relatedField: 'totalWhiteAnswers',
              required: true,
              errorMessage: `La respuesta en blanco ${i} es obligatoria.`
          });
      }
  }
  return of(modalFields);
}

}
