import { CommonModule } from "@angular/common";
import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ThemeCustomizerService } from "src/app/components/common/theme-customizer/theme-customizer.service";
import { Cursos } from "src/app/interface/cursos";
import { FechasService } from "src/app/service/util/fechas.service";

@Component({
  selector: "app-modal-detalles",
  standalone: true,
  templateUrl: "./modal-detalles.component.html",
  styleUrls: ["./modal-detalles.component.scss"],
  imports: [CommonModule],
  providers: [BsModalService],
})
export class ModalDetallesComponent {
  @Input() curso: Cursos;
  @ViewChild("verDetalles") private verDetalles: TemplateRef<any>;
  private modalRef: BsModalRef;
  backgroundColor: string = 'white';
  isToggled = false;

  constructor(
    public dateService: FechasService, public themeService: ThemeCustomizerService,
    private modalService: BsModalService
  ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
  });
  }

  toggleTheme() {
    this.themeService.toggleTheme();
}

  abrirModal() {
    const config: ModalOptions = {
      class: "modal-lg",
      ignoreBackdropClick: true,
      backdrop: 'static'
    };
    this.modalRef = this.modalService.show(this.verDetalles, config, );
  }

  cerrarModal() {
    this.modalRef.hide();
  }

}
