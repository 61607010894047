import { Component, ElementRef, ViewChild } from '@angular/core';
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MaterialService } from 'src/app/service/material.service';
import { CommonModule } from '@angular/common';
import { FechasService } from 'src/app/service/util/fechas.service';
import { PaginacionService } from 'src/app/service/util/paginacion.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { ToastrModule, ToastrService } from 'ngx-toastr';

interface Archivo {
  file: File;
  url?: string; // URL de vista previa para imágenes
}

@Component({
    selector: 'app-material-curso',
    standalone: true,
    templateUrl: './material-curso.component.html',
    styleUrl: './material-curso.component.scss',
    imports: [HeaderStyleOneComponent, SidebarMenuComponent, CommonModule, FormsModule,
      ToastrModule, RouterModule
    ],    
    providers:[BsModalService]
})
export class MaterialCursoComponent {
  isToggled = false;
  idCurso:string;
  idCalendario:string;
  nombreCurso:string;
  urlCurso:string;
  public compartirMarteialAlSubir=true;
  private modalRef: BsModalRef
  tiposMaterial:any; //Tipos de materiales
  materiales:any; //Materiales del curso
  material:any;
  materialesCompartidos:any; //Materiales compartidos del calendario
  isMobile: boolean

  //nuevo material
  nombre: string;
  tipo: any;
  descripcion: string;
  archivos: Archivo[] = [];
  paginatedMateriales: any
  currentPage: number = 1
  searchText: string = '';
  filteredMateriales: any;
  @ViewChild('archivoInput') archivoInput: ElementRef;

  constructor(
      public themeService: ThemeCustomizerService, private activatedRoute:ActivatedRoute,
      private materialService: MaterialService, public fechasService: FechasService,
      public paginacionService: PaginacionService, private modalService: BsModalService,
      private toastrService: ToastrService  ) {
      this.themeService.isToggled$.subscribe(isToggled => {
          this.isToggled = isToggled;
      });
        //Obtencion de la url del curso
    this.activatedRoute.params.subscribe(params =>{
      this.nombreCurso = params['nombreCurso'];
      this.urlCurso = params['urlCurso'];
      this.idCurso = params['idCurso'];
      console.log('params', params)
      if(params['idCalendario']!=undefined){
      this.idCalendario = params['idCalendario'];
    }else{
      this.compartirMarteialAlSubir=false;
    }
    })
    this.isMobile = window.innerWidth < 576;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 576;
      
    };
  }

  removeFocus(event: Event) {
    (event.target as HTMLElement).blur();
  }
  

  toggleTheme() {
      this.themeService.toggleTheme();
  }

  toggleExpand(data: any) {
    data.isExpanded = !data.isExpanded;
  }

  ngOnInit(): void {
    this.obtenerTiposMaterial();
    if(this.idCalendario!=undefined){
      this.obtenerMateriales();
    }else{
      this.obtenerMaterialesUrlCurso();
  }
  }

  obtenerMateriales() {
    this.materialService.materialCurso(this.idCalendario).subscribe(materiales => {
      this.materiales = materiales;
      this.filteredMateriales = materiales;
      this.updatePaginatedMateriales();
      console.log(this.materiales, 'materiales');
    }, error => {
      console.log(error);
    });
  }

  obtenerMaterialesUrlCurso() {
    this.materialService.materialCursoURl(this.urlCurso).subscribe(materiales => {
      this.materiales = materiales;
      this.filteredMateriales = materiales;
      this.updatePaginatedMateriales();
      console.log(this.materiales, 'materiales url curso');
    }, error => {
      console.log(error);
    });
  }

  obtenerTiposMaterial() {
    this.materialService.tiposMaterial().subscribe(tiposMaterial => {
      this.tiposMaterial = tiposMaterial;
      console.log(this.tiposMaterial, 'tipos material');
    }, error => {
      console.log(error);
    });
  }

  updatePaginatedMateriales() {
    this.paginatedMateriales = this.paginacionService.actualizarElementosPagina(this.filteredMateriales);
  }

  goToPage(page: number) {
    this.paginacionService.establecerPaginaActual(page);
    this.currentPage = page; // Actualiza el estado local del componente
    this.updatePaginatedMateriales();
  }

  getCurrentRangeStart(): number {
    return this.paginacionService.obtenerRangoInicio();
  }

  getTotalPages(): number {
    return this.paginacionService.obtenerTotalPaginas(this.filteredMateriales.length);
  }

  getCurrentRangeEnd(): number {
    return this.paginacionService.obtenerRangoFin(this.filteredMateriales.length);
  }

  getVisiblePages(): (number | string)[] {
    return this.paginacionService.obtenerPaginasVisibles(this.filteredMateriales.length);
  }

  // Método de filtrado
  filterMateriales(event: KeyboardEvent, field: string) {
    const inputElement = event.target as HTMLInputElement;
    const searchTextLower = inputElement.value.toLowerCase();
  
    this.filteredMateriales = this.materiales.filter(material => {
      let valueToFilter = material;
  
      // Acceder a propiedades anidadas (como users.name)
      field.split('.').forEach(key => {
        valueToFilter = valueToFilter[key];
      });
  
      // Convertir el valor filtrado a minúsculas y comparar con el texto de búsqueda
      return valueToFilter.toLowerCase().includes(searchTextLower);
    });
  
    this.updatePaginatedMateriales();
  }
  
  sortMateriales(prop: string, order: 'asc' | 'desc') {
    this.materiales.sort((a, b) => {
      let aValue = prop.split('.').reduce((o, i) => o[i], a);
      let bValue = prop.split('.').reduce((o, i) => o[i], b);
  
      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  
    this.updatePaginatedMateriales();
  }

  filterByPublicado(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const value = selectElement.value;
  
    if (value === '') {
      // Si se selecciona "Todos", mostrar todos los materiales
      this.filteredMateriales = this.materiales;
    } else {
      const shouldPublish = value === 'true';
      this.filteredMateriales = this.materiales.filter(material => material.publicado === shouldPublish);
    }
  
    this.updatePaginatedMateriales();
  }
  
  

  validarMiMaterial(material:any){
    let idUsuario = String(material.users['id']);
    var estatus = false;
    if( idUsuario != localStorage.getItem("idUsuario") && localStorage.getItem("rolUsuario") != 'admin'){
      estatus = false;
    }else if(idUsuario === localStorage.getItem("idUsuario") || localStorage.getItem("rolUsuario") === 'admin'){
      estatus = true;
    }
    return estatus;
  }
  
  eliminarMaterial(material:any){
    let idUsuario = String(material.users['id']);
    if( idUsuario != localStorage.getItem("idUsuario") && localStorage.getItem("rolUsuario") != 'admin'){

    }else if(idUsuario === localStorage.getItem("idUsuario") || localStorage.getItem("rolUsuario") === 'admin'){

      this.materialService.eliminarMaterial(material.idMaterial).subscribe(status =>{
        this.toastrService.success('Material eliminado correctamente', 'Éxito');
      if(this.idCalendario!=undefined){ 
        this.obtenerMateriales()
      }else{
        this.obtenerMaterialesUrlCurso()
      }
      },error => {
        console.log(error);
        this.toastrService.error('Error al eliminar el material', 'Error');
      });
    }
  }

  eliminarMaterialCompartido(idMaterialCompartido:string){
    this.materialService.eliminarMaterialCompartido(idMaterialCompartido).subscribe(resultado => {
      this.obtenerMateriales();
      this.toastrService.success('Material eliminado!', 'Exito');
    },error => {
      this.toastrService.error('Error al eliminar el material', 'Error');
    });
  }

  onFileSelected(event: any) {
    const selectedFiles: FileList = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file: File = selectedFiles[i];
      const archivo: Archivo = { file: file };

      // Verificar si es una imagen para mostrar una vista previa
      if (this.esImagen(file)) {
        const reader = new FileReader();
        reader.onload = (e: any) => archivo.url = e.target.result;
        reader.readAsDataURL(file);
      }

      this.archivos.push(archivo);
      const archivoControl = this.archivoInput.nativeElement
      archivoControl.markAsTouched();
    }
  }

  esImagen(file: File): boolean {
    return file.type.startsWith('image');
  }

  eliminarArchivo(index: number) {
    this.archivos.splice(index, 1);
  }

  guardarNuevoMaterial(form: any) {
    if (form.invalid) {
      this.toastrService.error('Por favor, completa todos los campos requeridos.', 'Error');
      return;
    }

    const formData = new FormData();
    formData.append('curso', this.idCurso);
    formData.append('users', localStorage.getItem("idUsuario"));
    formData.append('nombre', this.nombre);
    formData.append('tipoMaterial', this.tipo);
    formData.append('descripcion', this.descripcion);
    for (let i = 0; i < this.archivos.length; i++) {
      formData.append('file', this.archivos[i].file);
    }
    this.materialService.nuevoMaterial(formData).subscribe(
      (resultado) => {
        this.toastrService.success('Material guardado correctamente', 'Éxito');
        if(this.compartirMarteialAlSubir==true){
          this.compartirMaterial(resultado[0]["idMaterial"]);
        }
        if (this.idCalendario != undefined) {
          this.obtenerMateriales();
        } else {
          this.obtenerMaterialesUrlCurso();
        }
        this.cerrarModal();
        
      },
      (error) => {
        this.toastrService.error('Ocurrió un error al guardar el material', 'Error');
      }
    );
  }

  
  compartirMaterial(idMaterial: string){
    var jsonData = JSON.stringify({"calendario" : { "idCalendario":this.idCalendario}, "materialCurso":idMaterial,"activo":true});
    this.materialService.compartirMaterial(jsonData).subscribe(resultado => {
      this.obtenerMateriales();
      this.toastrService.success('Material compartido!', 'Exito');
    },error => {
      this.toastrService.error('Error al compartir el material', 'Error');
    });
  }

  abrirModalNuevoMaterial(nuevoMaterial: any) {
    this.modalRef = this.modalService.show(nuevoMaterial, { backdrop:'static'});
  }

  cerrarModal() {
    if(this.modalRef){
      this.modalRef.hide()
      this.nombre= ''
      this.tipo = null
      this.descripcion = ''
      this.compartirMarteialAlSubir = false
      this.archivos = []
    }
  }

  cancelarMaterial(){
    this.nombre= ''
      this.tipo = null
      this.descripcion = ''
      this.compartirMarteialAlSubir = false
      this.archivos = []
  }
}
