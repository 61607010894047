import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CursosService } from 'src/app/service/cursos.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { QuizCategory } from 'src/app/interface/categoria';
import { ToastrService } from 'ngx-toastr';
import { PaginacionComponent } from 'src/app/components/common/paginacion/paginacion.component';
import { PaginatePipe } from 'src/app/pipes/paginate.pipe';
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categorias-admin',
  standalone: true,
  imports: [CommonModule, PaginacionComponent, PaginatePipe, FormsModule, ReactiveFormsModule, ExamenesAdminComponent, SidebarMenuComponent, DynamicModalComponent],
  templateUrl: './categorias-admin.component.html',
  styleUrl: './categorias-admin.component.scss',
  providers: [BsModalService, ToastrService]
})
export class CategoriasAdminComponent {
  columns = [
    { key: 'category', title: 'Categoría', accordionKey: true },
    { key: 'image', title: 'Imagen', type: 'image' },
    { key: 'description', title: 'Descripción' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: null, buttonTwo: 'Editar'
      , buttonThree: null, buttonFour: null}
  ];

  seRestauroImg: boolean = false;
  //MODAL
  modalFields: any[] = [];
  initialData: any = null;
  modalTitle = 'Nueva Categoría';
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;

  categories: any[] = []
  originalCategories: any[] = []
  pageSize: number = 10
  currentPage: number = 1
  searchTerm: string = '';
  modalRef: any
  selectedFile: File | null = null;
  nombreImagen: string | null = null;
  previewImage: string | null = null;
  cursos: any[] = []
  formGuardarCategoria: FormGroup
  idCategoriaGuardar: number
  idUsuario: string;
  saving: boolean = false

  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private cursosService: CursosService, private formBuilder: FormBuilder,
    private toastrService: ToastrService) {
    this.idUsuario = localStorage.getItem('idUsuario');
  }

  ngOnInit(): void {
    this.obtenerTablaCategorias()
    this.obtenerCursosSelect()
    this.examenesService.restoreImage$.subscribe((estado: boolean) => {
      this.seRestauroImg = estado;
      // Aquí puedes realizar la lógica que necesites cuando se restaure la imagen
    });
  }

  obtenerTablaCategorias() {
    this.examenesService.obtenerTablaCategoriasAdmin().subscribe(
      (response: any[] = []) => {
        this.categories = response;
        this.categories.forEach(cat =>{
          cat.image = environment.urlImagenesCategorias + cat.image
        })
        console.log(this.categories, 'categorias obtenidas')
        this.originalCategories = [...response];
      },
      (error: any) => {
        console.error('Error obteniendo categorías', error);
      }
    );
  }

  private async obtenerCursosSelect() {
    const response = await this.cursosService.obtenerCursos()
    this.cursos = response
    // Agrega el nuevo objeto al final del arreglo
    this.cursos.push({ idCurso: 0, nombre: 'Sin curso' });
    // Ordenar los cursos alfabéticamente por la propiedad 'nombre'
    this.cursos.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  inicializarFormCategoria(datos: any) {
    // Si hay una imagen, mostrar la vista previa y deshabilitar el input
    this.previewImage = datos.image ? datos.image : null;
    const imagenControl = this.formBuilder.control({ value: datos.image ? datos.image : null, disabled: !!datos.image }, Validators.required);

    this.formGuardarCategoria = this.formBuilder.group({
      nombre: [datos.category !== null ? datos.category : null, Validators.required],
      imagen: imagenControl,
      curso: [datos.idCurso !== null ? datos.idCurso : null, Validators.required],
      descripcion: [datos.description ? datos.description : null, Validators.required],
    });
  }

  filterCategories(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.categories = this.originalCategories.filter(cat =>
      cat.category.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1; // Resetear a la primera página después de filtrar
  }
  imagenAnterior:any
  abrirModal(datos: any) {
    this.idCategoriaGuardar = datos ? datos.id : null
    this.imagenAnterior = datos ? datos.image : null
    this.modalTitle = datos ? 'Editar Categoría' : 'Nueva Categoría';
    this.modalFields = [
      {
        name: 'nombre', type: 'text', label: 'Nombre de la categoría*',
        required: true, errorMessage: 'El nombre es obligatorio.', value: datos ? datos.category : null,
        onlyNumbers: false, characterLimit: 50, characterMin: 3
      },
      {
        name: 'imagen', type: 'file', label: 'Imagen de la categoría',
        required: false, errorMessage: 'La imagen es obligatoria.', value: datos ? datos.image : null,
        categoryModal: true
      },
      {
        name: 'curso', type: 'select', label: 'Curso*', value: datos && datos.idCurso ? datos.idCurso : 0,
        required: true, options: this.cursos.map(option => ({ value: option.idCurso, label: option.nombre })), errorMessage: 'El curso es obligatorio.'
      },
      {
        name: 'descripcion', type: 'textarea', label: 'Descripción*', onlyNumbers: false, characterMin: 15,
        required: true, errorMessage: 'La descripción es obligatoria.', value: datos ? datos.description : null
      }
    ];
    this.initialData = datos ? this.modalFields : null;
    console.log(this.modalFields, 'fields')
    this.modalRef = this.modalService.show(this.dynamicModalTemplate, { backdrop:'static'});
  }

  // Método que captura la información al guardar en el modal
  onFormSubmit(data: any) {
    if(this.selectedFile){
      this.subirImagen(this.selectedFile, data)
    }else{
        if (this.initialData) {
            // Es edición
            this.actualizarCategoria(data);
          } else {
            // Es nueva categoría
            this.crearCategoria(data);
          }
    }
  }

  onFileSelected(file: any) {
    this.selectedFile = file.archivo;  // Almacena el archivo en una variable
  }

  subirImagen(file: File, data: any) {
    this.examenesService.subirImagen(file)
    .pipe(catchError(error => {
        console.error('Error al subir la imagen', error);
        return throwError(error)
    }))
    .subscribe(
        (response: { fileName: string }) => { // Cambia el tipo a lo que el backend devuelve
            console.log('Imagen subida con éxito, nombre del archivo:', response.fileName);
            this.nombreImagen = response.fileName;  // Guarda el nombre del archivo para futuras peticiones
            data.imagen = this.nombreImagen
            if (this.initialData) {
                // Es edición
                this.actualizarCategoria(data);
              } else {
                // Es nueva categoría
                this.crearCategoria(data);
              }
        }
    );
}


  crearCategoria(data: any) {
    this.saving = true
    let imagenAEnviar: any
    if(this.seRestauroImg === true){
      imagenAEnviar = this.imagenAnterior
    }
    else if(this.seRestauroImg === false && this.imagenAnterior !== null && 
      this.imagenAnterior !== data.imagen){
      imagenAEnviar = data.imagen
    }else if (this.seRestauroImg === false && this.imagenAnterior !== null &&
       this.imagenAnterior === data.imagen){
      imagenAEnviar = this.imagenAnterior
    }else if(this.seRestauroImg === false && this.imagenAnterior === null){
      imagenAEnviar = data.imagen
    }
    const request: QuizCategory = {
      category: data.nombre,
      image: imagenAEnviar,
      description: data.descripcion,
      recordUpdatedBy: parseInt(this.idUsuario),
      curso: { idCurso: parseInt(data.curso) }
    };
    console.log(request, 'request')
    this.examenesService.guardarCategoria(request).pipe(catchError(error => {
      this.saving = false
      this.toastrService.error("Error al guardar la categoria.", "Error");
      return throwError(error);
    })).subscribe(result => {
      this.saving = false
      this.toastrService.success("Categoria guardada con exito.", "Exito");
      this.cerrarModal()
      this.obtenerTablaCategorias();
    })
  }

  actualizarCategoria(data: any) {
    this.saving = true
    let imagenAEnviar: any
    if(this.seRestauroImg === true){
      imagenAEnviar = this.imagenAnterior
    }
    else if(this.seRestauroImg === false && this.imagenAnterior !== null && 
      this.imagenAnterior !== data.imagen){
      imagenAEnviar = data.imagen
    }else if (this.seRestauroImg === false && this.imagenAnterior !== null &&
       this.imagenAnterior === data.imagen){
      imagenAEnviar = this.imagenAnterior
    }else if(this.seRestauroImg === false && this.imagenAnterior === null){
      imagenAEnviar = data.imagen
    }
    const request: QuizCategory = {
      id: this.idCategoriaGuardar,  // Usar el id actual para editar
      category: data.nombre,
      image: imagenAEnviar,
      description: data.descripcion,
      recordUpdatedBy: parseInt(this.idUsuario),
      curso: { idCurso: parseInt(data.curso) }
    };
    console.log(`actualizar categoria ${JSON.stringify(request)}`)
    this.examenesService.guardarCategoria(request).pipe(catchError(error => {
      this.saving = false
      this.toastrService.error("Error al actualizar la categoria.", "Error");
      return throwError(error);
    })).subscribe(result => {
      this.saving = false
      this.toastrService.success("Categoria actualizada con exito.", "Exito");
      this.cerrarModal()
      this.obtenerTablaCategorias();
    })
  }

  cerrarModal() {
    this.modalRef.hide()
  }

}
