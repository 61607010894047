import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { categoriesDTO } from 'src/app/interface/material';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../../common/sidebar-menu/sidebar-menu.component";

@Component({
  selector: 'app-edicion-examenes',
  standalone: true,
  imports: [FormsModule, CommonModule, SidebarMenuComponent],
  templateUrl: './edicion-examenes.component.html',
  styleUrl: './edicion-examenes.component.scss',
  providers:[ToastrService]
})
export class EdicionExamenesComponent implements OnInit {
  categoriasTotales: any[] = [];
  categoriasAlumno: any[] = [];
  idUser: any;
  nameUser: any;
  selectedCategoryIds: number[] = [];
  initialCategoryIds: number[] = []; // Para almacenar la lista inicial de IDs

  constructor(
    private examenesService: ExamenesService,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.route.paramMap.subscribe(params => {
      this.idUser = Number(params.get('iduser'));
      this.nameUser = params.get('nameuser');
    });
  }

  ngOnInit(): void {
    this.obtenerCategoriasTotales();
    this.obtenerCategoriasAlumno();
  }

  obtenerCategoriasTotales() {
    this.examenesService.obtenerTablaCategoriasAdmin().subscribe(
      (response: any[] = []) => {
        this.categoriasTotales = response;
      },
      (error: any) => {
        console.error('Error obteniendo categorías', error);
      }
    );
  }

  obtenerCategoriasAlumno() {
    this.examenesService.obtenerCategorias(this.idUser).subscribe(
      (datosInicio: categoriesDTO) => {
        this.categoriasAlumno = datosInicio.categories;
        this.selectedCategoryIds = this.categoriasAlumno.map(category => category.id);
        this.initialCategoryIds = [...this.selectedCategoryIds]; // Guardar la lista inicial de IDs
      },
      error => {
        console.error(error);
      }
    );
  }

  isCategorySelected(id: number): boolean {
    return this.selectedCategoryIds.includes(id);
  }

  onCategoryToggle(id: number): void {
    if (this.selectedCategoryIds.includes(id)) {
      this.selectedCategoryIds = this.selectedCategoryIds.filter(categoryId => categoryId !== id);
    } else {
      this.selectedCategoryIds.push(id);
    }
  }

  // Verificar si la lista de categorías seleccionadas ha cambiado
  get isUpdateDisabled(): boolean {
    return this.arraysAreEqual(this.selectedCategoryIds, this.initialCategoryIds);
  }

  // Función para comparar dos listas sin importar el orden
  arraysAreEqual(arr1: number[], arr2: number[]): boolean {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  actualizarCategorias(): void {
    this.examenesService.actualizarCategoriasUsuario(this.idUser, this.selectedCategoryIds).subscribe(
      response => {
        console.log('Categorías actualizadas exitosamente:', response);
        this.toastrService.success('Categorías actualizadas exitosamente!', 'Exito');
        this.initialCategoryIds = [...this.selectedCategoryIds];
      },
      error => {
        console.error('Error al actualizar categorías:', error);
        this.toastrService.success('Error al actualizar las categorias', 'Error');
      }
    );
  }
}

