<app-sidebar-menu></app-sidebar-menu>
<div class="container">
    <div class="titles-container">
      <h3>{{ 'Alumno: ' + nameUser }}</h3>
      <h4>Examen y Series de examen</h4>
    </div>
    <div class="switch-container">
      <ng-container *ngFor="let categoria of categoriasTotales">
        <div class="switch-item">
          <label class="switch">
            <input 
              type="checkbox" 
              [checked]="isCategorySelected(categoria.id)"
              (change)="onCategoryToggle(categoria.id)"
            />
            <span class="slider"></span>
          </label>
          <span>{{ categoria.category }}</span>
        </div>
      </ng-container>
    </div>
    <div class="button-container">
        <button class="btn btn-success" (click)="actualizarCategorias()" 
        [disabled]="isUpdateDisabled">Actualizar</button>
      </div>
  </div>
  
  
  
