import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../common/theme-customizer/theme-customizer.service';
import { RouterLink } from '@angular/router';
import { SidebarMenuComponent } from "../../common/sidebar-menu/sidebar-menu.component";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { InicioService } from 'src/app/service/inicio.service';
import { CommonModule, formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';
import { EvaluacionesService } from 'src/app/service/evaluaciones.service';
import { FormsModule } from '@angular/forms';
import { PaginacionService } from 'src/app/service/util/paginacion.service';
import { FechasService } from 'src/app/service/util/fechas.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaginacionComponent } from "../../common/paginacion/paginacion.component";
import { PaginatePipe } from "../../../pipes/paginate.pipe";

@Component({
    selector: 'app-inicio',
    standalone: true,
    templateUrl: './inicio.component.html',
    styleUrl: './inicio.component.scss',
    providers: [BsModalService],
    imports: [RouterLink, SidebarMenuComponent, HeaderStyleOneComponent,
        CommonModule, FormsModule, PaginacionComponent, PaginatePipe]
})
export class InicioComponent implements OnInit {
    // idUsuario = localStorage.getItem("idUsuario");
    idInstructor = localStorage.getItem("idInstructor");
     idAlumno = localStorage.getItem("idAlumno");
    // nombreUsuario = localStorage.getItem("nombreUsuario");
    // email = localStorage.getItem("email");
     rolUsuario = localStorage.getItem("rolUsuario");
    // token = localStorage.getItem("token");

    loading = true;

    selectedYear: any;
    lineChartData: any;
    lineChartOptions: any;


    datosGraficaUsuarios: any;
    usuarios: any;
    cantidadTotalUsuarios: any;

    datosGraficaCursos: any;
    cursosProximos: any;
    cursosActivos: any;
    cantidadTotalCursos: any;

    /**variable para inicio de instructor  */
    cursosImpartidos: any = [];//guarda los cursos impartidos del instructor
    verDetallecursosImpartidos: boolean;
    cursosImpartidosDetalles: any;
    graficaCalificacionesEncuesta: any;
    evaluacionesInstructor: any;

    // variable para de uso para ruta de detalle publicaciones
    idCurso: any;
    idCalendario: any;
    nombreCurso: any;
    publicacionesCurso: any = [];
    displayPublicacionesCurso: boolean = false;

    /**variable para inicio de alumno  */
    ultimoCursoTerminado: any;
    ultimoCursoInscrito: any;

    //paginacion

    paginatedCursosImpartidos: any[] = [];
    cursosParaMostrar: any[] = [];
    currentPage: number = 1;
    itemsPerPage: number = 12;
    searchText: string = '';
    filteredCursosImpartidos: any[] = [];
    modalRef: BsModalRef
    currentPageTable: number = 1;
    pageSize: number = 5;


    isToggled = false;

    constructor(
        public themeService: ThemeCustomizerService, private inicioService: InicioService,
        private evaluacionesService: EvaluacionesService, private paginationService: PaginacionService,
        private fechasService: FechasService, private modalService: BsModalService) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        if (localStorage.getItem("rolUsuario") === "admin") {
            this.cargarAdministrador();
          } else if (localStorage.getItem("rolUsuario") === "Instructor") {
            this.cargarInstructor();
          } else if (localStorage.getItem("rolUsuario") === "student") {
            //  this.validarEncuestasPendientesAlumno();
             this.cargarAlumno();
            // this.obtenerNotificaciones();
          }
    }

    cargarInstructor() {
        this.inicioService.obtenerDashboardInstructor(localStorage.getItem("idInstructor")).subscribe(datosInicio => {
            this.cursosImpartidos = datosInicio;
            this.filteredCursosImpartidos = this.cursosImpartidos;
            this.updatePaginatedCourses();
            this.loading = false;
        }, error => {
            this.loading = false;
            console.error(error);
        });
    }

    cargarAdministrador() {
        this.inicioService.obtenerDashboardAdministrador().subscribe(datosAdministrador => {
            this.cursosImpartidos = datosAdministrador;
            this.filteredCursosImpartidos = this.cursosImpartidos;
            this.updatePaginatedCourses();
            this.loading = false;
        }, error => {
            this.loading = false;
            console.error(error);
        });
    }

      cargarAlumno() {
        forkJoin([
            this.inicioService.ultimoCursoInscrito(this.idAlumno),
            this.inicioService.ultimoCursoTerminado(this.idAlumno)
          ]).subscribe(([ultimoInscrito, ultimoTerminado]) => {
            this.ultimoCursoInscrito = ultimoInscrito;
            this.ultimoCursoTerminado = ultimoTerminado;
            console.log('inscrito', this.ultimoCursoInscrito, 'terminado', this.ultimoCursoTerminado)
            this.updateCursosParaMostrar();
          });
      }

      updateCursosParaMostrar() {
          if (this.rolUsuario === 'student') {
          this.cursosParaMostrar = [this.ultimoCursoInscrito, this.ultimoCursoTerminado].filter(course => course);
        }
      }

      // validarEncuestasPendientesAlumno() {
      //   this.evaluacionesService.validarEncuestasPendientes(String(localStorage.getItem("idAlumno"))).subscribe(encuestasPendientes => {
      //     if (encuestasPendientes === true) {
      //       // this.route.navigate(['/cursos/' + this.idUsuario], { queryParams: { encuestaPendiente: true } });

      //     }
      //   }, error => {
      //   //   this.msgs = [];
      //   //   this.msgs.push({ severity: 'error', summary: 'Error ' + error.status + ' '+this.lang.generic_msgError_encuesta, detail: this.lang.generic_detail +' ' + error.name });
      //   });
      // }

      onSearchTextChange(event: KeyboardEvent) {
        const inputElement = event.target as HTMLInputElement;
        this.searchText = inputElement.value || '';
        this.filterCourses();
    }

    abrirModalVerPublicaciones(verPublicaciones:any, idCurso: any, nombreCurso: any,
      idCalendario: any, publicacionesCurso: any) {
      this.idCurso = idCurso;
      this.nombreCurso = nombreCurso;
      this.idCalendario = idCalendario;
      this.publicacionesCurso = publicacionesCurso;
      console.log('pub curso', publicacionesCurso)
      this.displayPublicacionesCurso = true;
      this.modalRef = this.modalService.show(verPublicaciones, { class: 'modal-lg', backdrop: 'static' });
      // if (localStorage.getItem("rolUsuario") === "Instructor") {
      //   this.publicacionesCurso = null;
      //   this.cargarInstructor();
      // }
    }

    cerrarModal(){
      this.modalRef.hide()
    }



    filterCourses() {
        const searchTextLower = this.searchText.toLowerCase();
        if (this.rolUsuario === 'Instructor' || this.rolUsuario === 'admin') {
            this.filteredCursosImpartidos = this.cursosImpartidos.filter(course =>
                course.curso.toLowerCase().includes(searchTextLower)
            );
        } else {
            this.filteredCursosImpartidos = this.cursosImpartidos;
        }
        this.updatePaginatedCourses();
    }

  formatearFecha(fechaInicio: string, fechaFin: string): string {
    return this.fechasService.formatearFecha(fechaInicio, fechaFin);
  }

    getEncuestaValues(encuesta: string): { completed: number, total: number } {
        const [completed, total] = encuesta.split('/').map(Number);
        return { completed, total };
    }

    updatePaginatedCourses() {
        this.paginatedCursosImpartidos = this.paginationService.actualizarElementosPagina(this.filteredCursosImpartidos);
      }

    goToPage(page: number) {
        this.paginationService.establecerPaginaActual(page);
        this.currentPage = page; // Actualiza el estado local del componente
        this.updatePaginatedCourses();
      }

      getCurrentRangeStart(): number {
        return this.paginationService.obtenerRangoInicio();
      }

      getTotalPages(): number {
        return this.paginationService.obtenerTotalPaginas(this.filteredCursosImpartidos.length);
      }

      getCurrentRangeEnd(): number {
        return this.paginationService.obtenerRangoFin(this.filteredCursosImpartidos.length);
      }

      getVisiblePages(): (number | string)[] {
        return this.paginationService.obtenerPaginasVisibles(this.filteredCursosImpartidos.length);
      }

      onPageChange(newPage: number) {
        this.currentPageTable = newPage;
      }


}
