import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { catchError, throwError } from 'rxjs';
@Component({
  selector: 'app-subtemas',
  standalone: true,
  imports: [SidebarMenuComponent, ExamenesAdminComponent, DynamicModalComponent],
  templateUrl: './subtemas.component.html',
  styleUrl: './subtemas.component.scss',
  providers:[BsModalService, ToastrService]
})
export class SubtemasComponent {

  columns = [
    { key: 'subjectName', title: 'Materia' },
    { key: 'parentId', title: 'Padre' },
    { key: 'topicName', title: 'Tema(Id)', accordionKey:true },
    { key: 'description', title: 'Descripcion' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: null, buttonTwo: 'Editar',
       buttonThree: null, buttonFour: null}
  ];

  parentOptions = [
    {
      value: null,
      label: 'Seleccione una opcion'
    },
    {
    value: 0,
    label: 'Padre'
  }]

  temas: any[] = []
  materias: any[] = []
  originalTemas: any[] = []
  currentPage:number = 1
  searchTerm:string = ''
  temasPorMateria:any[] = []

   //MODAL
   modalFields: any[] = [];
   initialData: any = null;
   modalTitle = '';
   @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
   idTemaGuardar:number
   private modalRef: BsModalRef
   saving: boolean = false

  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private toastrService: ToastrService){
  }

  ngOnInit(): void {
  this.obtenerTemas()
  this.obtenerMateriasSelect()
  }

  // Método para manejar la apertura y cierre del menú
  onMenuToggle(isOpen: boolean) {
    if (isOpen) {
      console.log('El menú está abierto');
      // Lógica para manejar cuando el menú se abre
    } else {
      console.log('El menú está cerrado');
      // Lógica para manejar cuando el menú se cierra
    }
  }

  obtenerTemas() {
    this.examenesService.obtenerTemas().subscribe(
      (response: any[] = []) => {
        console.log(response, 'temas');
        // Mapeamos los temas para agregar la propiedad 'topicName' concatenada con el 'id'
        this.temas = response.map(tem => ({
          ...tem, // Mantiene todas las propiedades originales
          topicName: tem.topicName
              ? `${tem.topicName} (${tem.id})`
              : `Sin tema (${tem.id})`,
          subjectName: tem.subject?.name
              ? tem.subject.name
              : `Sin materia`
      }));
        this.originalTemas = [...this.temas]; // Copiamos la lista de temas original
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerMateriasSelect() {
    this.examenesService.obtenerPreguntasTabla().subscribe(
      (response: any[] = []) => {
        console.log(response, 'materias')
        this.materias = response
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerTemasPorMateria(subjectId: any | null, esEditar: boolean = false) {
    if (subjectId) {
        this.examenesService.obtenerTemasPorMateria(parseInt(subjectId.selectedValue ?
          subjectId.selectedValue : subjectId)).subscribe(
            (response: any[] = []) => {
                // Inicializar siempre como un arreglo vacío en caso de que sea null o undefined
                this.temasPorMateria = response ? response : [];

                // Actualizar las opciones de temas en el modal
                const padreField = this.modalFields.find(field => field.name === 'padreId');
                const padreFieldIndex = this.modalFields.findIndex(field => field.name === 'padreId');
                if (padreField) {

                    // Si no hay temas por materia, solo muestra la opción "Parent"
                    if (this.temasPorMateria.length === 0) {
                        console.log('No hay temas, mostrando solo la opción Parent');
                        padreField.options = [...this.parentOptions];

                    } else {
                        // Combina la opción "Parent" con los temas por materia si existen
                        padreField.options = this.parentOptions.concat(
                            this.temasPorMateria.map(option => ({
                                value: option.id,
                                label: option.topicName
                            }))
                        );
                    }
                    const valuesOptions = padreField.options.map((o: any) => o.value);
                    if(this.currentParentId && !valuesOptions.includes(this.currentParentId) || ((this.currentParentId === 0 || this.currentParentId === null) && !esEditar)){
                        padreField.value = null
                        this.modalFields[padreFieldIndex] = padreField;
                        const newRegistros = [...this.modalFields];
                        this.modalFields = [];
                        this.modalFields = [...newRegistros];
                    }
                }
            },
            (error: any) => {
                console.error('Error obteniendo temas por materia', error);
            }
        );
    }else{
        setTimeout(() => {
            const padreField = this.modalFields.find(field => field.name === 'padreId');
            const padreFieldIndex = this.modalFields.findIndex(field => field.name === 'padreId');
            if (padreField) {
                    console.log('No hay temas, mostrando solo la opción Parent');
                    padreField.options = [...this.parentOptions];
                    padreField.value = null;
                    this.modalFields[padreFieldIndex] = padreField;
                    const newRegistros = [...this.modalFields];
                    this.modalFields = [];
                    this.modalFields = [...newRegistros];
          }
        }, 500);
    }
}


  guardarTema(datos: any) {
    this.saving = true
    if(datos.padreId ===  null || datos.padreId === 'null'){
      this.saving = false
      this.toastrService.error("Seleccione un padre para el tema", "Error")
      return;
    }
    const request: any = {
      topicName: datos.nombre, // Aquí solo guardas el nombre limpio
      description: datos.descripcion,
      id: this.idTemaGuardar ? this.idTemaGuardar : null,
      subjectId: datos.materiaId,
      parentId: datos.padreId
    };

    console.log(request)
    this.examenesService.guardarTema(request)
    .pipe(catchError(error => {
        this.saving = false
        console.error('Error obteniendo materias', error);
        this.toastrService.error("No se pudo guardar el tema", "Error");
        return throwError(error)
    })).subscribe(
      (result: any) => {
        this.saving = false
        console.log(result);
        this.toastrService.success("Tema guardado correctamente!", "Éxito");
        this.obtenerTemas(); // Aquí se volverá a concatenar el ID
        this.cerrarModal();
      }
    );
  }

  filterTemas(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.temas = this.originalTemas.filter(tem =>
      tem.subjectName.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1; // Resetear a la primera página después de filtrar
  }
  currentParentId:any
  abrirModal(datos: any) {
    this.idTemaGuardar = datos ? datos.id : null;
    this.currentParentId = datos? datos.parentId : null
    this.modalTitle = datos ? 'Editar Tema' : 'Agregar Tema';

    // Limpiar el ID de 'topicName' si existe
    const cleanTopicName = datos ? datos.topicName.replace(/\s*\(\d+\)$/, '') : '';
    this.modalFields = [
      {
        name: 'materiaId', type: 'select', label: 'Materia*',
        options: this.materias.map(option => ({ value: option.id, label: option.name })),change:true,
        required: true, errorMessage: 'Materia es obligatorio.', value: datos ? datos.subject.id : null
      },
      {
        name: 'padreId', type: 'select', label: 'Seleccionar padre*',
        required: true, errorMessage: 'El padre es obligatorio.', value: datos ? datos.parentId : null,
      },
      {
        name: 'nombre', type: 'text', label: 'Nombre del tema*', characterMin: 3,
        required: true, errorMessage: 'El Nombre del tema es obligatorio.', value: cleanTopicName,
        onlyNumbers: false, characterLimit: 50
      },
      {
        name: 'descripcion', type: 'text', label: 'Descripcion*',onlyNumbers: false, characterMin: 15,
        required: true, errorMessage: 'Descripcion es obligatorio.', value: datos ? datos.description : null
      }
    ];
    this.obtenerTemasPorMateria(datos?.subject.id? datos.subject.id : null, true);
    this.initialData = datos ? this.modalFields : null;
    this.modalRef = this.modalService.show(this.dynamicModalTemplate, { backdrop:'static'});
  }

  cerrarModal() {
    this.modalRef.hide()
  }
}
