import { CommonModule } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { PaginacionComponent } from "src/app/components/common/paginacion/paginacion.component";
import { ThemeCustomizerService } from "src/app/components/common/theme-customizer/theme-customizer.service";
import { PaginatePipe } from "src/app/pipes/paginate.pipe";
import { FechasService } from "src/app/service/util/fechas.service";
import { SortingButtonsComponent } from "../../../common/sorting-buttons/sorting-buttons.component";
import { SortService } from "src/app/service/util/sort.service";

@Component({
  selector: "app-modal-diplomas-calendario",
  standalone: true,
  templateUrl: "./modal-diplomas-calendario.component.html",
  styleUrls: ["./modal-diplomas-calendario.component.scss"],
  imports: [CommonModule, PaginacionComponent, PaginatePipe, SortingButtonsComponent],
  providers: [BsModalService]
})
export class ModalDiplomasCalendarioComponent implements OnChanges {
  @ViewChild("verDiplomas") private verDiplomas: TemplateRef<any>;
  private modalRef: BsModalRef;
  tipoUsuario: string
  @Input() diplomas: any[];
  curso: string;
  currentPage: number = 1;
  pageSize: number = 5
  isMobile: boolean
  private sortOrder: "asc" | "desc" | null;
  private sortName: string = null;

  constructor(
    public themeService: ThemeCustomizerService,
    public dateService: FechasService,
    private modalService: BsModalService, private sortService: SortService
  ) {
    this.tipoUsuario = localStorage.getItem("rolUsuario");

    this.isMobile = window.innerWidth < 576;
    window.onresize = () => {
      this.isMobile = window.innerWidth < 576;
      
    };  
  }

  toggleExpand(data: any) {
    data.isExpanded = !data.isExpanded;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const cambiosDiplomas = changes.diplomas;
    if(cambiosDiplomas.currentValue !== null && cambiosDiplomas.currentValue !== cambiosDiplomas.previousValue){
        const listaDiploma: any[] = cambiosDiplomas.currentValue;
        if(listaDiploma.length !== 0){
            this.curso = listaDiploma[0].curso;
            this.currentPage = 1;
            this.abrirModal();
        }
    }
  }

  private abrirModal() {
    const config: ModalOptions = {
      class: "modal-lg",
      ignoreBackdropClick: true,
      backdrop:'static'
    };
    this.modalRef = this.modalService.show(this.verDiplomas, config);
  }

  cerrarModal() {
    this.modalRef.hide();
  }

  sortDiplomas(prop: string, order: "asc" | "desc" | null) {
    if (order === null) {
      return;
    }
    this.sortName = prop;
    this.sortOrder = order;
    this.diplomas = this.sortService.sort(this.diplomas, prop, order);
    this.diplomas = [...this.diplomas];
  }

  removeFocus(event: Event) {
    const target = event.target as HTMLElement;
    setTimeout(() => target.blur(), 100);
  }
  

  onPageChange(newPage: number) {
    this.currentPage = newPage;
  }
}
