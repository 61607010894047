<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
  [columns]="columns"
  [data]="preguntas"
  [title]="title"
  buttonText="Nueva Pregunta"
  (onPrimaryAction)="eliminarPregunta($event)"
  (onOpenModal)="abrirModal($event)">
</app-examenes-admin>

<ng-template #dynamicModal>
  <app-dynamic-modal
    [modalTitle]="modalTitle"
    [fields]="modalFields"
    [initialData]="initialData"
    [saving]="saving"
    (closeModal)="cerrarModal()"
    (formSubmit)="guardarPregunta($event)"
    (fileChange)="onFileSelected($event)"
    (selectChange)="handleQuestionTypeChange($event)">
  </app-dynamic-modal>
</ng-template>
