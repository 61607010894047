import { NgModule, OnInit } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditAccountPageComponent } from "./components/pages/edit-account-page/edit-account-page.component";
import { UsuariosPageComponent } from "./components/pages/usuarios-page/usuarios-page.component";
import { EvaluacionesPageComponent } from "./components/pages/evaluaciones-page/evaluaciones-page.component";
import { IniciarSesionPageComponent } from "./components/common/iniciar-sesion-page/iniciar-sesion-page.component";
import { RecuperarPasswordPageComponent } from "./components/common/recuperar-password-page/recuperar-password-page.component";
import { autenticacionGuard } from "./guard/autenticacion.guard";
import { CursosPageComponent } from "./components/pages/cursos-page/cursos-page.component";
import { InicioComponent } from "./components/pages/inicio/inicio.component";
import { DetallesCursoPageComponent } from "./components/pages/detalles-curso-page/detalles-curso-page.component";
import { AsistenciaPageComponent } from "./components/pages/asistencia-page/asistencia-page.component";
import { BibliotecaComponent } from "./components/pages/biblioteca-page/biblioteca.component";
import { BibliotecaDetallesComponent } from "./components/pages/biblioteca-detalles-page/biblioteca-detalles.component";
import { MaterialCursoComponent } from "./components/pages/material-curso-page/material-curso.component";
import { DiplomasPageComponent } from "./components/pages/diplomas-page/diplomas-page.component";
import { encuestaGuard } from "./guard/encuesta.guard";
import { ExamenesDashboardComponent } from "./components/pages/examenes-dashboard/examenes-dashboard.component";
import { CategoriasComponent } from "./components/pages/examenes-dashboard/examenes-alumno/categorias/categorias.component";
import { MisEstadisticasComponent } from "./components/pages/examenes-dashboard/examenes-alumno/mis-estadisticas/mis-estadisticas.component";
import { HacerExamenComponent } from "./components/pages/examenes-dashboard/examenes-alumno/categorias/hacer-examen/hacer-examen.component";
import { ResultadoExamenComponent } from "./components/pages/examenes-dashboard/examenes-alumno/categorias/resultado-examen/resultado-examen.component";
import { DetalleExamenComponent } from "./components/pages/examenes-dashboard/examenes-alumno/mis-estadisticas/detalle-examen/detalle-examen.component";
import { BancoPreguntasComponent } from "./components/pages/examenes-dashboard/examenes-admin/banco-preguntas/banco-preguntas.component";
import { CategoriasAdminComponent } from "./components/pages/examenes-dashboard/examenes-admin/categorias-admin/categorias-admin.component";
import { VerPreguntasComponent } from "./components/pages/examenes-dashboard/examenes-admin/ver-preguntas/ver-preguntas.component";
import { ExamenesTableComponent } from "./components/pages/examenes-dashboard/examenes-admin/examenes-table/examenes-table.component";
import { InstruccionesComponent } from "./components/pages/examenes-dashboard/examenes-admin/instrucciones/instrucciones.component";
import { TemasComponent } from "./components/pages/examenes-dashboard/examenes-admin/temas/temas.component";
import { SubtemasComponent } from "./components/pages/examenes-dashboard/examenes-admin/subtemas/subtemas.component";
import { AnalisisExamenComponent } from "./components/pages/examenes-dashboard/examenes-alumno/mis-estadisticas/analisis-examen/analisis-examen.component";
import { ActualizarPreguntasComponent } from "./components/pages/examenes-dashboard/examenes-admin/examenes-table/actualizar-preguntas/actualizar-preguntas.component";
import { UsuariosExamenesComponent } from "./components/pages/examenes-dashboard/examenes-admin/usuarios-examenes/usuarios-examenes.component";
import { ReporteExamenesComponent } from "./components/pages/examenes-dashboard/examenes-admin/usuarios-examenes/reporte-examenes/reporte-examenes.component";
import { EdicionExamenesComponent } from "./components/pages/examenes-dashboard/examenes-admin/usuarios-examenes/edicion-examenes/edicion-examenes.component";

export const routes: Routes = [
  { path: "iniciar-sesion", component: IniciarSesionPageComponent },
  { path: "recuperar-contrasena", component: RecuperarPasswordPageComponent },
  { path: "recuperar-contrasena/:email/:token", component: RecuperarPasswordPageComponent },
  {
    path: "",
    component: InicioComponent,
    data: { breadcrumb: "Inicio" },
    canActivate: [autenticacionGuard, encuestaGuard],
  },
  {
    path: "cursos",
    component: CursosPageComponent,
    data: { breadcrumb: "Cursos" },
    canActivate: [autenticacionGuard, encuestaGuard],
  },
  {
    path: "curso/material/:idCurso/:nombreCurso/:urlCurso/:idCalendario",
    component: MaterialCursoComponent,
    data: { breadcrumb: "Material :nombreCurso" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "curso/material/:idCurso/:nombreCurso/:urlCurso",
    component: MaterialCursoComponent,
    data: { breadcrumb: "Material :nombreCurso" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "curso/:idcalendario/:url",
    component: DetallesCursoPageComponent,
    data: { breadcrumb: "Detalles :url" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "asistencia/:idcalendario/:url",
    component: AsistenciaPageComponent,
    data: { breadcrumb: "Asistencias :url" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "biblioteca",
    component: BibliotecaComponent,
    data: { breadcrumb: "Biblioteca" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "biblioteca/detalles/:idRepositorio/:url",
    component: BibliotecaDetallesComponent,
    data: { breadcrumb: "Detalles :url" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "diplomas",
    component: DiplomasPageComponent,
    data: { breadcrumb: "Diplomas" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "perfil",
    component: EditAccountPageComponent,
    data: { breadcrumb: "Perfil" },
    canActivate: [autenticacionGuard, encuestaGuard]
  },
  {
    path: "usuarios",
    component: UsuariosPageComponent,
    data: { breadcrumb: "Usuarios" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "evaluaciones",
    component: EvaluacionesPageComponent,
    data: { breadcrumb: "Evaluaciones" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "examenes",
    component: ExamenesDashboardComponent,
    data: { breadcrumb: "Examenes" },
    canActivate: [autenticacionGuard]
  },{
    path: "categorias",
    component: CategoriasComponent,
    data: { breadcrumb: "Categorias" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "mis-estadisticas/:iduser?/:nameuser?/:view?",
    component: MisEstadisticasComponent,
    data: { breadcrumb: "Mis Estadisticas" },
    canActivate: [autenticacionGuard]
  },
  {
    path: 'hacer-examen/:slug',
    component: HacerExamenComponent,
    canActivate: [autenticacionGuard]
  },
  {
    path: 'resultado-examen',
    component: ResultadoExamenComponent,
    canActivate: [autenticacionGuard]
  },
  {
    path: "detalle-examen/:slug",
    component: DetalleExamenComponent,
    data: { breadcrumb: "Detalle Examen" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "analisis-examen/:slug",
    component: AnalisisExamenComponent,
    data: { breadcrumb: "Analisis Examen" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "banco-preguntas",
    component: BancoPreguntasComponent,
    data: { breadcrumb: "Temas de la pregunta" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "examenes-table",
    component: ExamenesTableComponent,
    data: { breadcrumb: "Cuestionarios" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "actualizar-preguntas/:quizId/:examenName",
    component: ActualizarPreguntasComponent,
    data: { breadcrumb: "Actualizar preguntas" },
    canActivate: [autenticacionGuard]
  },  
  {
    path: "instrucciones",
    component: InstruccionesComponent,
    data: { breadcrumb: "Instrucciones" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "temas",
    component: TemasComponent,
    data: { breadcrumb: "Temas" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "subtemas",
    component: SubtemasComponent,
    data: { breadcrumb: "Subtemas" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "usuarios-examenes",
    component: UsuariosExamenesComponent,
    data: { breadcrumb: "Usuarios de Examenes" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "reporte-examenes/:iduser/:nameuser/:email",
    component: ReporteExamenesComponent,
    data: { breadcrumb: "Reporte de Examenes" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "edicion-examenes/:iduser/:nameuser",
    component: EdicionExamenesComponent,
    data: { breadcrumb: "Edicion de Examenes" },
    canActivate: [autenticacionGuard]
  },
  {
    path: "ver-preguntas/:subjectId/:name",
    component: VerPreguntasComponent,
    data: { breadcrumb: "Temas de la pregunta" },
    canActivate: [autenticacionGuard]
  },  
  {
    path: "categorias-admin",
    component: CategoriasAdminComponent,
    data: { breadcrumb: "Categorias Admin" },
    canActivate: [autenticacionGuard]
  },
  { path: "**", component: CursosPageComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{})],
  exports: [RouterModule],
})
export class AppRoutingModule{}

