import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { ExamenesService } from 'src/app/service/examenes.service';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-usuarios-examenes',
  standalone: true,
  imports: [ExamenesAdminComponent, DynamicModalComponent, SidebarMenuComponent],
  templateUrl: './usuarios-examenes.component.html',
  styleUrl: './usuarios-examenes.component.scss',
  providers: [BsModalService, ToastrService]
})
export class UsuariosExamenesComponent implements OnInit {
  columns = [
    { key: 'name', title: 'Nombre', accordionKey: true },
    { key: 'email', title: 'Correo' },
    { key: 'image', title: 'Imagen', type:'image' },
    { key: 'role', title: 'Rol' },
    {
      key: 'actions', title: 'Acciones', type: 'actions', buttonOne: 'Reporte evaluaciones',
      buttonTwo: 'Editar', buttonThree: 'Edicion de examenes', buttonFour: 'Eliminar'
    }
  ];

  roleOptions = [
    {
      value: 1,
      label: 'Owner'
    },
    {
      value: 2,
      label: 'Admin'
    },
    {
    value: 5,
    label: 'Alumno'
    },
    {
      value: 6,
      label: 'Usuario Padre'
    },
    {
      value: 7,
      label: 'Instructor'
    },
    {
      value: 8,
      label: 'Desafio de Aplicacion'
    },
    {
      value: 9,
      label: 'Biblioteca'
    }
  ]
  selectedUserId:any
  //MODAL
  modalFields: any[] = [];
  initialData: any = null;
  modalTitle = '';
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  @ViewChild('confirmDeleteModal') confirmDeleteModalTemplate: TemplateRef<any>;
  private modalRef: BsModalRef
  private modalRefEvaluacion: BsModalRef
  saving: boolean = false
  idUsuarioGuardar:number
  usuarios: any[] = []
  seRestauroImg: boolean
  imagenAnterior: any
  selectedFile: File | null = null;
  modalResult: { icon: string; message: string } | null = null;
  nombreImagen: string;
  imagenRestauradaAlGuardar: boolean

  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private toastrService: ToastrService, private router: Router) {

  }

  ngOnInit(): void {
    this.obtenerUsuarios()
    this.examenesService.restoreImage$.subscribe((estado: boolean) => {
      this.seRestauroImg = estado;
    });
  }

  obtenerUsuarios() {
    this.examenesService.obtenerUsuarios().subscribe(
      (response: any[] = []) => {
        // Mapea la respuesta y traduce los roles
        this.usuarios = response.map(user => {
          if (user.roleId === 2) {
            user.role = 'Admin';
          } else if (user.roleId === 5) {
            user.role = 'Alumno';
          } else if (user.roleId === 1) {
            user.role = 'Owner';
          }
          else if (user.roleId === 6) {
            user.role = 'Usuario Padre';
          }
          else if (user.roleId === 7) {
            user.role = 'Instructor';
          }
          else if (user.roleId === 8) {
            user.role = 'Desafio de Aplicacion';
          }
          else if (user.roleId === 9) {
            user.role = 'Biblioteca';
          }
          if(user.image === ''){
            user.image = "assets/img/user.png"
          }else if(!user.image.includes()){
            user.image = environment.urlImagenesUsuarios + user.image
          }
          // Ajusta la propiedad actions según el rol
          user.actions = {
            buttonOne: user.role !== 'Alumno' ? null : 'Reporte evaluaciones',
            buttonTwo: 'Editar', // Siempre visible
            buttonThree: user.role !== 'Alumno' ? null : 'Edicion de examenes',
            buttonFour: user.role !== 'Alumno' ? null : 'Eliminar'
          };

          return user;
        });
        console.log(this.usuarios)
      },
      (error: any) => {
        console.error('Error obteniendo usuarios', error);
      }
    );
  }

  // Método para ajustar los botones según el rol
  adjustActionsForRole(role: string) {
    if (role === 'Alumno') {
      return {
        buttonOne: 'Reporte evaluaciones', // Activar
        buttonTwo: 'Editar', // Activar
        buttonThree: 'Edicion de examenes', // Activar
        buttonFour: 'Eliminar' // Activar
      };
    }
    return {
      buttonOne: null, // Desactivar
      buttonTwo: 'Editar', // Siempre visible
      buttonThree: null, // Desactivar
      buttonFour: null // Desactivar
    };
  }
  
  abrirModal(datos: any) {
    if(datos?.role !== 'Owner'){
      this.idUsuarioGuardar = datos? datos.id : 0
      this.imagenAnterior = datos?.image
      ? datos.image.replace(environment.urlImagenesUsuarios, '') // Remover la URL base
      : null;
      this.modalTitle = datos ? 'Editar Usuario' : 'Nuevo Usuario';
      if(!datos){
        this.modalFields = [
          {
            name: 'name', type: 'text', label: 'Nombre*', characterMin: 3,
            required: true, errorMessage: 'El titulo es obligatorio.', value: datos ? datos.name : null,
            onlyNumbers: false, characterLimit: 50
          },
          {
            name: 'user', type: 'text', label: 'Usuario*', characterLimit: 20, onlyNumbers: false,
            required: true, errorMessage: 'El codigo es obligatorio.', value: datos ? datos.username : null,
            characterMin: 3, noSpecialCharacters:true
          },
          {
            name: 'email', type: 'email', label: 'Correo*', characterLimit: 50, onlyNumbers: false,
            required: true, errorMessage: 'El codigo es obligatorio.', value: datos ? datos.email : null,
            characterMin: 10
          },
          {
            name: 'password', type: 'password', label: 'Contrasena*', characterLimit: 30,
            required: true, errorMessage: 'El codigo es obligatorio.', value: datos? datos.password : null,
            characterMin: 5
          },
          {
            name: 'confirmPassword', type: 'password', label: 'Confirmar contrasena*', characterLimit: 30,
            required: true, errorMessage: 'El codigo es obligatorio.', value: datos? datos.password : null,
            characterMin: 5
          },
          {
            name: 'roleId', type: 'select', label: 'Rol*', required: true,
            errorMessage: 'El codigo es obligatorio.', value: datos ? datos.roleId : null,
            options: this.roleOptions
          },
          {
            name: 'phone', type: 'number', label: 'Telefono*', required: true, characterMin: 5,
            errorMessage: 'El codigo es obligatorio.', value: datos? datos.phone : null,
            characterLimit: 20
          },
          {
            name: 'address', type: 'textarea', label: 'Direccion de compra',
            characterLimit: 100, onlyNumbers: false, required: false,
            errorMessage: 'El codigo es obligatorio.', value: datos? datos.address : null,
            characterMin: 10
          },
          {
            name: 'imagen', type: 'file', label: 'Imagen', required: false,
            errorMessage: 'El codigo es obligatorio.', value: datos? datos.image : null
          }
        ];
      }else{
        this.modalFields = [
          {
            name: 'name', type: 'text', label: 'Nombre*', characterMin: 3,
            required: true, errorMessage: 'El titulo es obligatorio.', value: datos ? datos.name : null,
            onlyNumbers: false, characterLimit: 50
          },
          {
            name: 'user', type: 'text', label: 'Usuario*', characterLimit: 20, onlyNumbers: false,
            required: true, errorMessage: 'El codigo es obligatorio.', value: datos ? datos.username : null,
            characterMin: 3, noSpecialCharacters:true
          },
          {
            name: 'email', type: 'email', label: 'Correo*', characterLimit: 50, onlyNumbers: false,
            required: true, errorMessage: 'El codigo es obligatorio.', value: datos ? datos.email : null,
            characterMin: 10
          },
          {
            name: 'roleId', type: 'select', label: 'Rol*', required: true,
            errorMessage: 'El codigo es obligatorio.', value: datos ? datos.roleId : null,
            options: this.roleOptions
          },
          {
            name: 'phone', type: 'number', label: 'Telefono*', required: true, characterMin: 5,
            errorMessage: 'El codigo es obligatorio.', value: datos? datos.phone : null,
            characterLimit: 20
          },
          {
            name: 'address', type: 'textarea', label: 'Direccion de compra',
            characterLimit: 100, onlyNumbers: false, required: false,
            errorMessage: 'El codigo es obligatorio.', value: datos? datos.address : null,
            characterMin: 10
          },
          {
            name: 'imagen', type: 'file', label: 'Imagen', required: false,
            errorMessage: 'El codigo es obligatorio.', value: datos? datos.image : null
          }
        ];
      }
      this.initialData = datos ? this.modalFields : null;
      this.modalRef = this.modalService.show(this.dynamicModalTemplate, { backdrop: 'static' });
    }else{
      this.toastrService.error("No tienes permisos para acceder", "Error")
    }
  }

  guardarUsuario(data: any) {
    this.saving = true;
  
    if (this.selectedFile) {
      this.subirImagenUsuario(this.selectedFile, data);
    }
  
    if (data.password !== data.confirmPassword) {
      this.toastrService.error('Las contraseñas no coinciden', "Error");
      this.saving = false;
      return;
    }
  
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(data.email)) {
      this.toastrService.error("El formato del email no es válido", "Error");
      this.saving = false;
      return;
    }
  
    let imagenAEnviar: any;
    if (this.seRestauroImg === true) {
      imagenAEnviar = this.imagenAnterior;
    } else if (this.seRestauroImg === false && this.imagenAnterior !== null && this.imagenAnterior !== data.imagen) {
      imagenAEnviar = data.imagen;
    } else if (this.seRestauroImg === false && this.imagenAnterior !== null && this.imagenAnterior === data.imagen) {
      imagenAEnviar = this.imagenAnterior;
    } else if (this.seRestauroImg === false && this.imagenAnterior === null) {
      imagenAEnviar = data.imagen;
    }
  
    const request: any = {
      id: this.idUsuarioGuardar,
      name: data.name,
      email: data.email,
      image: imagenAEnviar ? imagenAEnviar : '',
      roleId: data.roleId,
      user: data.user,
      password: data.password,
      phone: data.phone,
      address: data.address
    };
  
    console.log(request, 'request');
    
    this.examenesService.guardarUsuario(request).pipe(
      catchError(error => {
        this.saving = false;
        
        // Capturar el mensaje de error específico del backend
        const errorMessage = error.error?.error || "Error al guardar el usuario.";
        this.toastrService.error(errorMessage, "Error");
        
        console.log(error);
        return throwError(error);
      })
    ).subscribe(result => {
      this.saving = false;
      this.toastrService.success("Usuario guardado con éxito.", "Éxito");
      this.cerrarModal();
      this.obtenerUsuarios();
    });
  }
  

  subirImagenUsuario(file: File, data: any) {
    this.examenesService.subirImagenUsuario(file)
    .pipe(catchError(error => {
        console.error('Error al subir la imagen', error);
        return throwError(error)
    }))
    .subscribe(
        (response: { fileName: string }) => {
            console.log('Imagen subida con éxito, nombre del archivo:', response.fileName);
            this.nombreImagen = response.fileName;
            data.imagen = this.nombreImagen
        }
    );
}

  irAReportes(iduser: number) {
    // Buscar el usuario en el arreglo this.usuarios que coincida con iduser
    const usuario = this.usuarios.find(user => user.id === iduser);
  
    if (usuario) {
      const nameuser = usuario.name; 
      const email = usuario.email
      this.router.navigate([`/reporte-examenes`, iduser, nameuser, email]);
    } else {
      console.warn(`Usuario con id ${iduser} no encontrado.`);
    }
  }

  irAEdicionExamenes(event: any) {
   this.router.navigate([`/edicion-examenes`, event.id, event.name]);
  }
  
  eliminarUsuario(event:any, modal:any){
    this.selectedUserId = event.id;
    this.modalRefEvaluacion = this.modalService.show(modal, { backdrop: 'static' });
  }

  cerrarModalEvaluacion(){
    this.modalRefEvaluacion.hide()
  }

  confirmDelete() {
    this.examenesService.eliminarUsuario(this.selectedUserId).pipe(catchError(error => {
      this.toastrService.error("Error al eliminar el usuario.", "Error");
      return throwError(error);
    })).subscribe(result => {
      this.toastrService.success("Usuario eliminado con exito.", "Exito");
      this.cerrarModalEvaluacion()
      this.obtenerUsuarios()
    })
   }
  
  onFileSelected(file: any) {
    this.selectedFile = file.archivo;  // Almacena el archivo en una variable
  }

  onMenuToggle(isOpen: boolean) {
    if (isOpen) {
    } else {
    }
  }

  cerrarModal() {
    this.modalRef.hide()
  }
} 
