import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../theme-customizer/theme-customizer.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { IniciarSesionService } from "src/app/service/iniciar-sesion.service";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-recuperar-password-page",
  standalone: true,
  imports: [RouterLink, ToastrModule, FormsModule, CommonModule],
  templateUrl: "./recuperar-password-page.component.html",
  styleUrls: ["./recuperar-password-page.component.scss"],
})
export class RecuperarPasswordPageComponent implements OnInit {
  private token: string;
  isToggled = false;
  ingresarPassword: boolean = false;
  correo: string;
  newPassword: string;
  verifyNewPassword: string;
  passwordVisible: boolean;
  passwordVisibleConfirm: boolean;

  constructor(
    activatedRoute: ActivatedRoute,
    public themeService: ThemeCustomizerService,
    private route: Router,
    private toastrService: ToastrService,
    private inicioSesionService: IniciarSesionService
  ) {
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
    activatedRoute.params.subscribe((params) => {
      this.correo = params.email;
      this.token = params.token;
      if (this.token && this.correo) {
        this.ingresarPassword = true;
      }
    });
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {}

  validarMetodoEjecutar() {
    if (this.ingresarPassword) {
      this.restaurarPassword();
    } else {
      this.enviarCorreoRecuperacion();
    }
  }

  private enviarCorreoRecuperacion() {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!this.correo || this.correo.length === 0) {
    this.toastrService.error("Debe ingresar un email", "Error");
  } else if (!emailRegex.test(this.correo)) {
    this.toastrService.error("El formato del email no es válido", "Error");
  } else {
    let formData: FormData = new FormData();
    formData.append("email", this.correo);

    this.inicioSesionService.correoRecuperacion(formData).subscribe(
      (data) => {
        const mensaje: string = "Enviamos un correo de recuperación a tu email.";
        this.toastrService.success(mensaje, "Éxito");
        this.route.navigate(["/iniciar-sesion"]);
      },
      (error) => {
        if (error.status === 401) {
          this.toastrService.error(`El usuario no fue encontrado`, `Error`);
        } else {
          this.toastrService.error(
            `Detalles: ${error.name}`,
            `Error ${error.status}`
          );
        }
      }
    );
  }
}


  private restaurarPassword() {
    if (this.newPassword.length < 6) {
      this.toastrService.error(
        `Las contraseñas deben tener minimo 6 caracteres.`,
        `Error`
      );
    } else if (this.newPassword != this.verifyNewPassword) {
      this.toastrService.error(
        `Las contraseñas que ingresaste no coinciden.`,
        `Error`
      );
    } else {
      let formData: FormData = new FormData();
      formData.append("email", this.correo);
      formData.append("token", this.token);
      formData.append("password", this.newPassword);
      formData.append("confirmacion", this.verifyNewPassword);

      this.inicioSesionService.restablecerContrasena(formData).subscribe(
        (data) => {
          this.toastrService.success(
            "Tu contraseña se ha restablecido.",
            "Exito"
          );
          this.route.navigate(["/iniciar-sesion"]);
        },
        (error) => {
          this.toastrService.error(
            `Detalles: ${error.name}`,
            `Error ${error.status}`
          );
        }
      );
    }
  }

  get passwordFieldType(): string {
    return this.passwordVisible ? "text" : "password";
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  get passwordConfirmFieldType(): string {
    return this.passwordVisibleConfirm ? "text" : "password";
  }

  togglePasswordConfirmVisibility(): void {
    this.passwordVisibleConfirm = !this.passwordVisibleConfirm;
  }
}
