<div class="container">
<div class="report-container">
    <div class="report-title">
        <h4>Detalles de {{nombreUsuario}}</h4>
    </div>
    <div class="profile-container">
        <div class="img-container">
            <img src="assets/img/user.png" alt="">
        </div>
        <div class="user-info-container">
            <h4>{{nombreUsuario}}</h4>
            <span>{{email}}</span>
        </div>
    </div>
    <div class="general-boxes-container">
        <div class="reportes-title">
            <h4>Reportes</h4>
        </div>
        <div class="boxes-container">
            <div class="box-1">
                <div class="box-title">
                    <h4>Historia de examen</h4>
                </div>
                <div class="detail-button" (click)="irAMisEstadisticasHistoria()" 
                style="cursor: pointer;">
                    <span>VER DETALLES</span>
                </div>
            </div>
            <div class="box-2">
                <div class="box-title">
                    <h4>Por examen</h4>
                </div>
                <div class="detail-button" (click)="irAMisEstadisticasPorExamen()" 
                style="cursor: pointer;">
                    <span>VER DETALLES</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="right-panel">
    <div class="right-panel-title">
        <h4>Reporte de Progreso</h4>
    </div>
     <!-- Gráfico Circular de Rendimiento General -->
  <div class="chart-container">
    <h5>Rendimiento General</h5>
    <canvas id="rendimientoGeneralChart"></canvas>
  </div>

  <!-- Gráfico de Barras de Rendimiento por Examen -->
  <div class="chart-container">
    <h5>Rendimiento por Examen</h5>
    <canvas id="rendimientoPorExamenChart"></canvas>
  </div>
</div>
</div>