import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ThemeCustomizerService } from "src/app/components/common/theme-customizer/theme-customizer.service";
import { EvaluacionesService } from "src/app/service/evaluaciones.service";

@Component({
  selector: "app-modal-evaluaciones",
  standalone: true,
  templateUrl: "./modal-evaluaciones.component.html",
  styleUrls: ["./modal-evaluaciones.component.scss"],
  imports: [CommonModule, FormsModule],
  providers: [BsModalService],
})
export class ModalEvaluacionesComponent implements OnChanges {
  @ViewChild("verEvaluacion") private verEvaluacion: TemplateRef<any>;
  @Input() private idCalendario: number;
  private modalRef: BsModalRef;
  @Input() tipoEvaluacion: string;
  @Output() accionEncuesta: EventEmitter<number> = new EventEmitter();
  nombreCurso: string;
  preguntasEvaluacion: any[];
  comentarioLength: number = 20;
  msjErrorForm: boolean = false;
  enviandoRespuesta: boolean = false;

  constructor(
    public themeService: ThemeCustomizerService,
    private modalService: BsModalService,
    private evaluacionesService: EvaluacionesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.tipoEvaluacion.currentValue !== undefined &&
      changes.tipoEvaluacion.currentValue !== null
    ) {
      this.obtenerEvaluaciones(this.tipoEvaluacion, this.idCalendario);
    }
  }

  cerrarModal() {
    this.modalRef.hide();
    this.accionEncuesta.emit(1);
  }
  private obtenerEvaluaciones(tipoEvaluacion: string, idCalendario: any) {
    this.evaluacionesService
      .obtenerEvaluacionCurso(
        tipoEvaluacion,
        idCalendario,
        String(localStorage.getItem("idAlumno"))
      )
      .subscribe((datosEncuesta) => {
        this.nombreCurso = `${datosEncuesta[0]["curso"]} (${datosEncuesta[0]["modalidad"]})`;
        this.preguntasEvaluacion = datosEncuesta[0]["preguntasEncuestas"];
        this.enviandoRespuesta = false;
        const config: ModalOptions = {
          class: "modal-lg",
          ignoreBackdropClick: true,
          backdrop:'static'
        };
        this.modalRef = this.modalService.show(this.verEvaluacion, config);
      });
  }

  guardarEncuesta(form: NgForm) {
    if (form.invalid) {
      this.msjErrorForm = true;
      this.mostrarMensajeError(form);
      setTimeout(() => {
        this.msjErrorForm = false;
      }, 3000);
    } else {
      this.enviandoRespuesta = true;
      const tipo = { tipo: this.tipoEvaluacion === "final" };
      const respuestas = form.value;
      const examQualityResponse = { ...tipo, ...respuestas };
      const jsonData = JSON.stringify({
        idCalendario: this.idCalendario,
        idUser: localStorage.getItem("idUsuario"),
        examQualityResponse,
      });

      this.evaluacionesService
        .realizarEvaluacion(jsonData)
        .subscribe((data: any) => {
          this.modalRef.hide();
          this.accionEncuesta.emit(0);
        });
    }
  }

  mostrarMensajeError(form: NgForm) {
    const valuesItems: any = form.value;
    for (const key in valuesItems) {
      if (Object.prototype.hasOwnProperty.call(valuesItems, key)) {
        const value: string = valuesItems[key];
        if (
         (key.includes("respuesta") && value === "") ||
          (key.includes("comentario") && value !== "" && value.length < this.comentarioLength)
        ) {
            if(key.includes("comentario")){
                console.log(value)
            }
          const idMessageError = key.includes("respuesta")
            ? key.replace("respuesta", "mensajeErrorRes")
            : key.includes("comentario")
            ? key.replace("comentario", "mensajeErrorCom")
            : key;
          console.log(idMessageError);
          if (idMessageError.includes("mensajeError")) {
            document.getElementById(idMessageError).style.display = "block";
          }
        }
      }
    }
  }

  seleccionado(form: NgForm, valueItem: string, index: number) {
    const item = `${valueItem}${index}`;
    const idMensajeError = `mensajeErrorRes${index}`;
    const valor = form.value[item];
    if (valor) {
      document.getElementById(idMensajeError).style.display = "none";
    }
  }

  ocultarDiv(form: NgForm, valueItem: string, index: number) {
    const item = `${valueItem}${index}`;
    const idMensajeError = `mensajeErrorCom${index}`;
    const valor: string = form.value[item];
    if (valor) {
      const valorLength = valor.trim().length;
      if (valorLength >= this.comentarioLength) {
        document.getElementById(idMensajeError).style.display = "none";
      }
    } else if (valor == "") {
      document.getElementById(idMensajeError).style.display = "none";
    }
  }
}
