import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ExamenesService } from 'src/app/service/examenes.service';


@Component({
  selector: 'app-dynamic-modal',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, CKEditorModule],
  templateUrl: './dynamic-modal.component.html',
  styleUrl: './dynamic-modal.component.scss',
  providers: [BsModalService]
})
export class DynamicModalComponent {
  @Input() initialData: any = null;
  @Input() fields: any[] = [];
  @Input() modalTitle: string;
  @Input() saving: boolean = false;
  @Output() formSubmit = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  @Output() selectChange = new EventEmitter<any>();
  @Output() fileChange = new EventEmitter<any>();

  form: FormGroup;

  // TemplateRef del modal
  @ViewChild('dynamicModal') modalTemplate: TemplateRef<any>;

  changeImage: any[] = [];     // Controla si se muestra el input de cambiar imagen
  previousImage: string | null = null; // Guarda la imagen previa
  hasFileSelected: boolean[] = [];
  public Editor = ClassicEditor;
  dynamicFields: any[] = [];
  originalInitialData: any[] = [];
  private fieldTemp: any[] = []

  constructor(private fb: FormBuilder, private examenesService: ExamenesService) { }

  ngOnInit(): void {
    this.buildForm();
    this.originalInitialData = [...this.initialData]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialData && !changes.initialData.firstChange) {
      console.log('cambia data')
      this.buildForm();
    }
    if (changes.fields && !changes.fields.firstChange) {
      console.log('cambia data 2')
      this.fieldTemp = changes.fields.previousValue;
      this.reBuildForm();
    }
  }

  buildForm() {
    const controlsConfig = {};
    this.fields.forEach(field => {
      const value = this.initialData ? field.value : '';
      controlsConfig[field.name] = [value, field.required ? Validators.required : []];
      // Guarda la imagen previa
      if (field.type === 'file' && field.value) {
        this.previousImage = field.value;
      }
    });
    this.form = this.fb.group(controlsConfig);
  }

  // Método para guardar los errores actuales de los controles
  private saveControlErrors(): { [key: string]: any } {
    const controlErrors = {};
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if (control && control.errors) {
        controlErrors[key] = control.errors;
      }
    });
    return controlErrors;
  }

  // Método para restaurar los errores en los controles
  private restoreControlErrors(controlErrors: { [key: string]: any }, controlsConfig: any): {} {
    Object.keys(controlErrors).forEach(key => {
      const control = this.form.get(key);  // Aquí usas this.form.get(key)
      const field = this.fields.filter(f => f.name === key)
      if (control) {
        control.setErrors(controlErrors[key]);
      }
      if(field.length !== 0){
        controlsConfig[key] = control;  // Este es opcional, depende de si lo necesitas
      }
    });

    return controlsConfig;
  }


  reBuildForm() {
    // Guarda los errores actuales antes de reconstruir el formulario
    const controlErrors = this.saveControlErrors();

    const controlsConfig = {};
    const totalOptionsIndex = this.fields.findIndex(f => f.name === "totalOptions");
    const esUltimoField = totalOptionsIndex === -1 ? false : (this.fields.length - 1) === totalOptionsIndex;
    this.fields.forEach(field => {
      const value = this.initialData ? field.value : '';
      const valueTemp = this.fieldTemp.filter(v => v.name === field.name);
      if (!valueTemp) {
        controlsConfig[field.name] = [value, field.required ? Validators.required : []];
        if (field.type === 'file' && field.value) {
          this.previousImage = field.value;
        }
      } else {
        const control = this.form.get(field.name);
        if (control === null) {
          if (field.required) {
            controlsConfig[field.name] = new FormControl(field.value, Validators.required);
          } else {
            controlsConfig[field.name] = new FormControl(field.value);
          }
        } else {
            if(field.name === "totalOptions" && esUltimoField){
                control.setValue(null)
            }else if(field.name === "padreId" && (value === null || value === '')){
                control.setValue(null)
            }

            controlsConfig[field.name] = control;
        }
      }
    });
    // Restaura los errores a los controles correspondientes

    // Reconstruye el formulario
    const newControlConfig = this.restoreControlErrors(controlErrors, controlsConfig);
    this.form = this.fb.group(newControlConfig);
    console.log(JSON.stringify(this.changeImage))
  }
  submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.formSubmit.emit(this.form.value);
  }

  deleteImage(index: number, fieldName: string, label: string): void {
    // Actualiza el valor del campo a null para eliminar la imagen
    this.form.get(fieldName).setValue(null);
    this.changeImage[index] = true; // Habilita la carga de una nueva imagen si se desea
    this.hasFileSelected[index] = false; // Marca que no hay imagen seleccionada
    const response = { id: label === 'Cargar' ? 1 : 0, archivo: null };
    this.fileChange.emit(response);
    this.examenesService.restoreImage(false);
  }

  toggleImageChange(index: number): void {
    this.changeImage[index] = !this.changeImage[index];
    this.examenesService.restoreImage(false); 
  }

  restorePreviousImage(index: number, value: string, name: string): void {
    this.changeImage[index] = false;
    this.form.get(name).setValue(value);
    this.hasFileSelected[index] = false; // No hay archivo nuevo seleccionado
    // Emitir el evento de restauración
    this.examenesService.restoreImage(true); 
  }

  onFileChange(event: any, label: string, index: number, name: string): void {
    const file = event.target.files[0];
    if (file) {
      this.hasFileSelected[index] = true; // Marca que hay un archivo seleccionado
      this.form.get(name).setValue(file.name);
      const response = { id: label === 'Cargar' ? 1 : 0, archivo: file };
      this.fileChange.emit(response);  // Emitir el archivo seleccionado al componente padre
    } else {
      this.hasFileSelected[index] = false; // Marca que no hay archivo seleccionado
    }
    this.examenesService.restoreImage(false);
  }

  deleteSelectedImage(index: number): void {
    const fileInput = document.querySelector(`input[type="file"][data-index="${index}"]`) as HTMLInputElement;

  if (fileInput) {
    fileInput.value = '';  // Elimina el archivo seleccionado
  }
    this.hasFileSelected[index] = false; // Quitar la selección de imagen
    this.form.get(this.fields[index].name).setValue(null); // Limpiar el valor en el formulario
    const response = { id: this.fields[index].label === 'Cargar' ? 1 : 0, archivo: null };
    this.fileChange.emit(response);
    this.examenesService.restoreImage(false);
  }


  onSelectChange(event: Event, field: any) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectChange.emit({ selectedValue, fieldName: field.name });
  }

  containsLettersValidator(field: any) {
    const control = this.form.get(field.name);
    if (field.onlyNumbers === false) {
      if (control && control.value && control.value.length > 0) {
        const hasLetters = /[a-zA-Z]/.test(field.type === 'editor' ? this.stripHtmlTags(control.value) : control.value);
        const currentErrors = control.errors || {}; // Obtener los errores actuales
        if (!hasLetters) {
          control.setErrors({ ...currentErrors, noLetters: true }); // Mantén los errores actuales y añade el nuevo
        } else {
          delete currentErrors['noLetters']; // Elimina el error si ya contiene letras
          control.setErrors(Object.keys(currentErrors).length ? currentErrors : null); // Solo setear si aún hay errores
        }
      }
    }
  }

  characterLimitValidator(field: any) {
    const control = this.form.get(field.name);
  
    if (control && control.value) {
      let valueToValidate = control.value;
  
      // Si el campo es de tipo editor, eliminar las etiquetas HTML antes de la validación
      if (field.type === 'editor') {
        valueToValidate = this.stripHtmlTags(control.value);
  
        // Reemplazar "&nbsp;" por espacios reales
        valueToValidate = valueToValidate.replace(/&nbsp;/g, ' ');
      }
  
      const currentErrors = control.errors || {}; // Obtener los errores actuales
  
      // Validar el límite máximo de caracteres
      if (field.characterLimit && this.countDigits(valueToValidate) > field.characterLimit) {
        currentErrors['characterLimitExceeded'] = true;
      } else {
        delete currentErrors['characterLimitExceeded']; // Elimina este error si ya no aplica
      }
  
      // Validar el mínimo de caracteres
      if (field.characterMin && this.countDigits(valueToValidate) < field.characterMin) {
        currentErrors['characterMinNotMet'] = true;
      } else {
        delete currentErrors['characterMinNotMet']; // Elimina este error si ya no aplica
      }
  
      if(field.type !== 'number'){
 // Validar si hay más espacios que letras u otros caracteres
 const numSpaces = (valueToValidate.match(/\s/g) || []).length; // Cuenta los espacios normales
 const numNonSpaces = valueToValidate.replace(/\s/g, '').length; // Cuenta caracteres sin espacios

 if (numSpaces > numNonSpaces) {
   currentErrors['moreSpacesThanCharacters'] = true; // Agrega un error personalizado
 } else {
   delete currentErrors['moreSpacesThanCharacters']; // Elimina el error si ya no aplica
 }
      }
     
  
      control.setErrors(Object.keys(currentErrors).length ? currentErrors : null); // Aplica los errores actuales
    }
  }

  noSpecialCharactersValidator(field: any) {
    const control = this.form.get(field.name);
    
    if (field.noSpecialCharacters) {
      const currentErrors = control.errors || {}; // Obtener los errores actuales
      
      // Expresión regular para permitir letras, números, guion y guion bajo solamente
      const noSpecialCharsRegex = /^[a-zA-Z0-9-_]*$/;
      const valueToValidate = field.type === 'editor' ? this.stripHtmlTags(control.value) : control.value;
      
      // Si el valor tiene caracteres especiales, agregar el error
      if (control && control.value && !noSpecialCharsRegex.test(valueToValidate)) {
        currentErrors['specialCharactersNotAllowed'] = true;
      } else {
        delete currentErrors['specialCharactersNotAllowed']; // Elimina el error si ya no aplica
      }
      
      // Aplica los errores actuales
      control.setErrors(Object.keys(currentErrors).length ? currentErrors : null);
    }
  }
  

  // Método para remover etiquetas HTML del texto
  stripHtmlTags(value: string): string {
    return value.replace(/<\/?[^>]+(>|$)/g, ''); // Eliminar todas las etiquetas HTML
  }

  // Método para contar la cantidad de dígitos en un número o valor
  countDigits(value: any): number {
    // Convertir a cadena y eliminar todo lo que no sea un dígito (incluye signos, puntos decimales, etc.)
    const digitString = value.toString()
    return digitString.length; // Retorna la cantidad de dígitos
  }

  getCurrentCharacterLimit(characterLimit: number) {
    return characterLimit;
  }

  onKeyUp(event: KeyboardEvent, field: any): void {
    if (!field.onlyNumbers) {
      this.containsLettersValidator(field); // Validar letras
    }
  
    if (field.characterLimit || field.characterMin) {
      this.characterLimitValidator(field); // Validar límite de caracteres
    }
  
    if (field.noSpecialCharacters) {
      this.noSpecialCharactersValidator(field); // Validar caracteres especiales
    }
  }

  preventInvalidInput(event: KeyboardEvent): void {
    const invalidChars = ['e', 'E', '+', '-', '.'];

    // Evitar cualquier tecla no numérica, incluida la "e", "E", "+" y "-"
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }


  hasFieldErrors(): boolean {
    if (this.form) {
      // Verifica si algún control tiene errores
      return Object.keys(this.form.controls).some(key => {
        const control = this.form.get(key);
        return control && control.invalid && control.touched;
      });
    }
    return true; // Si no hay formulario, retornar true para deshabilitar
  }

  campos = ['duracion', 'totalPuntos', 'aprobacion']
  obtenerError(control: AbstractControl, name: string) {
    if (this.campos.includes(name)) {
      console.log(`Errores ${name} ${JSON.stringify(control.errors)}`)
    }
    if (control.hasError('characterLimitExceeded')) {
      // console.log(`${name} ,`, control);
      return true;
    }
    return false;
  }

  onCloseModal() {
    this.closeModal.emit();
  }
}
