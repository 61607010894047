import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { ActivatedRoute } from '@angular/router';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { ModalPreguntaService } from 'src/app/service/util/modal-pregunta.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-ver-preguntas',
  standalone: true,
  imports: [ExamenesAdminComponent, SidebarMenuComponent, DynamicModalComponent],
  templateUrl: './ver-preguntas.component.html',
  styleUrl: './ver-preguntas.component.scss',
  providers:[BsModalService, ToastrService]
})
export class VerPreguntasComponent {
  columns = [
    { key: 'subject', title: 'Materia' },
    { key: 'topicName', title: 'Tema', accordionKey:true },
    { key: 'questionType', title: 'Tipo' },
    { key: 'questionText', title: 'Pregunta' },
    { key: 'marks', title: 'Marcas' },
    { key: 'difficultyValue', title: 'Dificultad' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne:'Eliminar', buttonTwo:'Editar',
       buttonThree: null, buttonFour: null}
  ];
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  pageSize:number = 10
  currentPage:number = 1
  subjectId: number;
  materiaName:string;
  preguntas: any[] = [];
  title:string = ''
  modalFields:any[] = []
  originalModalFields:any[] = [];
  camposOpcionesFormulario: any[] = [];
  questionTypeValueSelected:any
  modalRef: BsModalRef
  initialData:any[] = []
  modalTitle:string
  temasPorMateria:any[] = []
  idPreguntaGuardar:any
  idMateriaGuardar:any
  currentOptions:any[] = []
  saving: boolean = false
  seRestauroImg: boolean = false

  constructor(private route: ActivatedRoute, private examenesService: ExamenesService,
    private modalPreguntaService: ModalPreguntaService, private modalService: BsModalService,
    private toastrService: ToastrService) {

    }

  ngOnInit(): void {
    this.subjectId = +this.route.snapshot.paramMap.get('subjectId')!;
    this.materiaName = this.route.snapshot.paramMap.get('name')!;
    this.obtenerPreguntas();
    this.examenesService.restoreImage$.subscribe((estado: boolean) => {
      this.seRestauroImg = estado;
    });
  }

  obtenerPreguntas() {
    this.examenesService.verPreguntasPorMateria(this.subjectId).subscribe(
      (response: any[] = []) => {
        this.preguntas = response.map(pregunta => {
          return {
            ...pregunta,
            difficultyValue: this.traducirDificultad(pregunta.difficulty)
          };
        });
        this.title = this.materiaName + ' Preguntas';
        console.log(this.preguntas, 'preguntas')
      },
      (error: any) => {
        console.error('Error obteniendo preguntas', error);
      }
    );
  }

  traducirDificultad(difficulty: string): string {
    const traducciones = {
      easy: 'Fácil',
      medium: 'Medio',
      hard: 'Difícil'
    };

    // Retorna la traducción correspondiente o el valor original si no coincide
    return traducciones[difficulty] || difficulty;
  }

  obtenerTemasPorMateria(subjectId: any) {
    if (subjectId) {
        this.examenesService.obtenerTemasPorMateria(parseInt(subjectId.selectedValue?
          subjectId.selectedValue : subjectId)).subscribe(
            (response: any[] = []) => {
                this.temasPorMateria = response;

                // Actualizar las opciones de temas en el modal
                const topicField = this.modalFields.find(field => field.name === 'topic');
                if (topicField) {
                    let totalOptions: any[] = this.temasPorMateria.map(option => ({
                      value: option.id,
                      label: option.topicName
                  }))
                  topicField.options = totalOptions
                }
            },
            (error: any) => {
                console.error('Error obteniendo temas por materia', error);
            }
        );
    }
}

  eliminarPregunta(id:any){
    console.log(id)
    this.examenesService.eliminarPregunta(id)
    .pipe(catchError(error => {
        this.toastrService.error('Error al eliminar la pregunta', 'Error');
        return throwError(error)
    })).subscribe(result => {
      this.obtenerPreguntas()
      this.toastrService.success('Pregunta eliminada!', 'Exito');
    });
  }

  private fileMap:Map<string, File> = new Map<string, File>();
  private fileNewMap:Map<string, File> = new Map<string, File>();
  private fileListaMap:Map<number, Map<string, File>> = new Map<number, Map<string, File>>();

  onFileSelected(file: any) {
    const archivo = file.archivo
    if(file.id === 0){
        this.fileMap.set(archivo.name, archivo)
        this.fileListaMap.set(file.id, this.fileMap)
    }else{
        this.fileNewMap = new Map<string, File>();
        if(archivo === null){
          this.fileListaMap.set(file.id, null)
        }else{
          this.fileNewMap.set(archivo.name, archivo);
          this.fileListaMap.set(file.id, this.fileNewMap)
        }
    }
  }

  guardarPregunta(data: any) {
    this.saving = true
    let optionsArray = [];
    const questionType = data.questionType
    const totalElements = parseInt(data.totalOptions ? data.totalOptions : data.totalWhiteAnswers, 10);
    let correctAnswers
    let correctAnswersMultiple:any[] = []
    let totalCorrectAnswers
    let marks:number
    let optionRequest: any = {};
    console.log(data)
    for (let i = 1; i <= totalElements; i++) {
      switch (questionType) {
        case 'radio': // Pregunta de opción única
        let filePath: string = data[`optionImage${i}`];
        let fileName = filePath ? filePath.includes('\\') ? filePath.split('\\').pop() : filePath : '';
          optionRequest = {
            option_value: data[`option${i}`] || "", // El valor de la opción
            has_file: fileName ? 1 : 0, // Si hay archivo, 1; si no, 0
            file_name: this.fileMap.get(fileName) ? fileName : fileName.length > 0 ? fileName : "",
          };

          correctAnswers = data.answerNumber
          totalCorrectAnswers = 1
          marks = 1
          break;

        case 'checkbox': // Pregunta con múltiples opciones
         let filePathCheck = data[`optionImage${i}`];
         let fileNameCheck = filePathCheck ? filePathCheck.split('\\').pop() : '';

          optionRequest = {
            option_value: data[`option${i}`] || "", // El valor de la opción
            has_file: fileNameCheck ? 1 : 0, // Si hay archivo, 1; si no, 0
            file_name: this.fileMap.get(fileNameCheck) ? fileNameCheck : "",
          };
           const currentAnswerSave = data[`correctAnswer${i}`]
          totalCorrectAnswers = parseInt(data.correctAnswersNumber)
            if(currentAnswerSave !== undefined && currentAnswerSave !== null && currentAnswerSave !== ''){
              correctAnswersMultiple?.push({ answer: currentAnswerSave});
            }
          break;

        case 'blanks': // Pregunta tipo completar frase
          optionRequest = {
            optionValue: data[`whiteAnswer${i}`] || "",
            hasFile: 0,
            fileName: "",
          };
          totalCorrectAnswers = 1
          correctAnswers = 0
          break;

        case 'match': // Pregunta de tipo unir preguntas con respuesta
          optionRequest = {
            optionValue: data[`leftOption${i}`] + data[`rightOption${i}`] || "",
            hasFile: 0,
            fileName: "",
          };
          totalCorrectAnswers = parseInt(data.totalOptions, 10);
          for (let j = 1; j <= totalCorrectAnswers; j++) {
            correctAnswers.push({ answer: data[`answer${j}`] });
          }
          break;

        case 'para':
        case 'video':
        case 'audio':
          optionRequest = {
            optionValue: data[`leftOption${i}`] + data[`rightOption${i}`] || "",
            hasFile: 0,
            fileName: "",
          };
          totalCorrectAnswers = parseInt(data.totalOptions, 10);
          for (let j = 1; j <= totalCorrectAnswers; j++) {
            correctAnswers.push({ answer: data[`answer${j}`] });
          }
          break;

        default:
          console.warn("Tipo de pregunta no reconocido:", questionType);
          break;
      }
      optionsArray.push(optionRequest);
    }
    console.log(JSON.stringify(correctAnswersMultiple), 'correcta')
    const request = {
      id: this.idPreguntaGuardar,
      subjectId: this.idMateriaGuardar,
      question: data.question,
      questionType: data.questionType,
      status: 1,
      topic: data.topic,
      totalOptions: data.totalOptions,
      difficultyLevel: data.difficulty,
      timeToInvest: data.time,
      tips: data.tips || '',
      explanation: data.description || '',
      totalAnswers: optionsArray.length,
      totalCorrectAnswers: totalCorrectAnswers,
      hint: data.tips || '',
      marks: data.marks? data.marks : 1,
      questionFileIsUrl: 0,
      questionFile:'',
      file:data.file ? data.file.split('\\').pop() : '',
      correctAnswers: correctAnswersMultiple.length > 0? JSON.stringify(correctAnswersMultiple) : correctAnswers,
      options: optionsArray
    };
    const listFile = []
    const firstList = this.fileListaMap.get(1);
    const secondList = this.fileListaMap.get(0);
    if(firstList){
        firstList.forEach((file, key) => {
          if(this.seRestauroImg === true){
            listFile.push(this.imagenGlobalAnterior);
            request.file = this.imagenGlobalAnterior
          }
          else if(this.seRestauroImg === false && this.imagenGlobalAnterior !== null && 
            this.imagenGlobalAnterior !== data.file){
              listFile.push(file);
            request.file = key
          }else if (this.seRestauroImg === false && this.imagenGlobalAnterior !== null &&
             this.imagenGlobalAnterior === data.file){
              listFile.push(this.imagenGlobalAnterior);
            request.file = this.imagenGlobalAnterior
          }else if(this.seRestauroImg === false && this.imagenGlobalAnterior === null){
            listFile.push(file);
            request.file = key
          }
        });
    }else if(data.file === null && !this.seRestauroImg){
      request.file = null
    }
    if(secondList){
        secondList.forEach((file, key) => {
            listFile.push(file);
        });
    }

    console.log(request)
    console.log(listFile)
    this.examenesService.guardarPregunta(request, listFile.length > 0 ? listFile : [])
    .pipe(catchError(error => {
        this.saving = false
        this.toastrService.error("No se pudo guardar la pregunta", "Error");
        return throwError(error)
    }))
    .subscribe(
      (result) => {
        this.saving = false
        this.toastrService.success("Se guardó la pregunta correctamente", "Éxito");
        this.cerrarModal();
        this.obtenerPreguntas();
      }
    );
  }
  editMode: boolean
  imagenGlobalAnterior: string
  abrirModal(datos: any) {
    this.editMode = false
      this.idPreguntaGuardar = datos? datos.id : null
      this.idMateriaGuardar = this.subjectId
      this.imagenGlobalAnterior = datos ? datos.questionFile : null
      let newModalFields = [];
      this.currentOptions = [];
      console.log(datos, 'datos')
      this.modalTitle = datos? 'Editar Pregunta' : 'Cargar Pregunta';
        this.obtenerTemasPorMateria(this.subjectId)
      this.modalFields = [
        {
          name: 'topic', type: 'select', label: 'Tema*', value:datos?.topicId? datos.topicId : null,
          required: true, errorMessage: 'El tema es obligatorio.'
        },
        {
          name: 'question', type: 'editor', label: 'Pregunta*', value:datos?.questionText? datos.questionText : '',
          required: true, errorMessage: 'La pregunta es obligatoria.', onlyNumbers:false, characterMin: 10
        },
        {
          name: 'questionType', type: 'select', label: 'Tipo de pregunta*', change: true,
          value:datos?.questionType? datos.questionType : '',
          required: true, errorMessage: 'El tipo de pregunta es obligatorio.',
          options: this.modalPreguntaService.typeQuestionOptions.map(option => ({ value: option.id, label: option.label }))
        },
        {
          name: 'file', type: 'file', label: 'Cargar', required: false,preguntaModal:true,
          value:datos?.questionFile? `${environment.urlImagenesPreguntas}${datos.questionFile}` : null,
        },
        {
          name: 'difficulty', type: 'select', label: 'Nivel de dificultad*',
          value:datos?.difficulty ? datos.difficulty : '',
          required: true, errorMessage: 'El nivel de dificultad es obligatorio..',
          options: this.modalPreguntaService.difficultyLevelOptions.map(option => ({ value: option.id, label: option.label }))
        },
        {
          name: 'tips', type: 'text', label: 'Tips', characterMin: 3,
          placeholder: 'Sugerencia para la pregunta', required: false,
          value:datos?.tips? datos.tips : '', onlyNumbers:false,
        },
        {
          name: 'description', type: 'editor', label: 'Explicacion', required: false,
          value:datos?.explanation? datos.explanation : '', onlyNumbers:false, characterMin: 15,
        },
        {
          name: 'percentage', type: 'number', label: 'Puntuaciones*',
          required: true, errorMessage: 'La puntuacion es obligatoria.', value: datos?.marks? datos.marks : 1,
          characterLimit:3
        },
        {
          name: 'time', type: 'number', label: 'Tiempo a invertir (seg)*',
          required: true, errorMessage: 'El tiempo a invertir es obligatorio.',
          value:datos?.timeSpend? datos.timeSpend : '', placeholder:'Exprese valor en segundos',
          characterLimit:5
        },
      ];
      let fieldNameCreados;
      if(datos){
        switch (datos.questionType) {
            case 'radio':
            case 'checkbox':
            case 'match':
              this.modalPreguntaService.generateTotalOptions(newModalFields, datos.options.length);
              fieldNameCreados = ['totalOptions']
              break;
            case 'blanks':
              this.modalPreguntaService.generateTotalWhiteAnswers(newModalFields, datos.options.length);
              break;
            case 'para':
            case 'video':
            case 'audio':
              this.modalPreguntaService.generateTotalQuestionsAndTotalOptions(newModalFields);
              break;
            default:
              break;
          }
      }
      const newModalFieldsTemp = [...this.modalFields,...newModalFields];
      this.modalFields = newModalFieldsTemp;
      this.originalModalFields = [...newModalFieldsTemp]
    if(datos){
        this.editMode = true
        this.currentOptions = datos.options
        this.questionTypeValueSelected = datos.questionType
        this.modalPreguntaService.interpretarCamposACrear(datos.questionType, datos.options.length,
          fieldNameCreados, this.modalFields, datos.options, datos.correctAnswers).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = [...resultModalFields]
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          });
      }

    this.initialData = datos ? this.modalFields : null;
    this.modalRef = this.modalService.show(this.dynamicModalTemplate, { class: 'modal-lg', backdrop:'static'});
  }

  handleQuestionTypeChange(event: any) {
    const selectedType = event.selectedValue
    const fieldName = event.fieldName;
    let newModalFields = [];
    // Limpiar campos existentes
    if (fieldName === 'questionType') {
      this.questionTypeValueSelected = selectedType;
      this.originalModalFields = this.originalModalFields.filter(field =>
        field.name !== 'totalOptions' &&
        field.name !== 'totalWhiteAnswers' &&
        field.name !== 'totalQuestions'
      );

      // Añadir campos según el tipo de pregunta seleccionada
      switch (selectedType) {
        case 'radio':
        case 'checkbox':
        case 'match':
          this.modalPreguntaService.generateTotalOptions(newModalFields)
          break;
        case 'blanks':
          this.modalPreguntaService.generateTotalWhiteAnswers(newModalFields);
          break;
        case 'para':
        case 'video':
        case 'audio':
          this.modalPreguntaService.generateTotalQuestionsAndTotalOptions(newModalFields);
          break;
        default:
          break;
      }

      const newModalFieldsTemp = [...this.originalModalFields,...newModalFields];
      this.modalFields = [...newModalFieldsTemp];
      this.originalModalFields = [...newModalFieldsTemp]
    }
    else if (fieldName === 'totalOptions') {
      if (this.questionTypeValueSelected === 'radio') {
        this.modalPreguntaService.generateOptionsWithImage(selectedType, [], this.currentOptions).subscribe(
          (resultModalFields: any[] = []) => {
            this.camposOpcionesFormulario = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );

      } else if (this.questionTypeValueSelected === 'checkbox') {
        this.modalPreguntaService.generateOptionsWithMultipleAnswers(selectedType, [],
          this.currentOptions, null, this.editMode? newModalFields : null).subscribe(
          (resultModalFields: any[]) => {
            this.camposOpcionesFormulario = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      } else if (this.questionTypeValueSelected === 'match') {
        this.modalPreguntaService.generateMatchQuestions(selectedType, this.modalFields).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      } else if (this.questionTypeValueSelected === 'para' || this.questionTypeValueSelected === 'video'||
        this.questionTypeValueSelected === 'audio') {
        this.modalPreguntaService.generateQuestionsAndOptions(selectedType, 'options', this.modalFields).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      }
      const newModalFieldsTemp = [...this.originalModalFields,...this.camposOpcionesFormulario];
      this.modalFields = [...newModalFieldsTemp];
    }
    else if (fieldName === 'totalQuestions') {
      if (this.questionTypeValueSelected === 'para' || this.questionTypeValueSelected === 'video'||
        this.questionTypeValueSelected === 'audio') {
        console.log('entro en totalquestions')
        this.modalPreguntaService.generateQuestionsAndOptions(selectedType, 'questions', this.modalFields).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      }
    }
    else if (fieldName === 'totalWhiteAnswers') {
      this.modalPreguntaService.generateWhiteAnswers(selectedType, this.modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          this.modalFields = resultModalFields
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );
    }
    else if (fieldName === 'correctAnswersNumber') {
      this.modalPreguntaService.generateCorrectAnswerFields(selectedType, this.modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          this.modalFields = resultModalFields;
        },
        (error: any) => {
          console.error('Error generando respuestas correctas', error);
        }
      );
    }
  }

  cerrarModal() {
    this.modalRef.hide()
  }
}

