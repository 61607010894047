<div class="modal-header">
    <h5 class="modal-title">{{ modalTitle }}</h5>
    <button type="button" class="btn-close" (click)="onCloseModal()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div *ngFor="let field of fields; let i = index" class="mb-3">
            <label>{{ field.label }}</label>

            <!-- Input de texto -->
            <input *ngIf="field.type === 'text'" [placeholder]="field.placeholder ? field.placeholder : ''" type="text"
                class="form-control" [formControlName]="field.name" (keyup)="onKeyUp($event, field)">

            <!-- Input de password -->
            <input *ngIf="field.type === 'password'" type="password"
            class="form-control" [formControlName]="field.name" (keyup)="onKeyUp($event, field)">

            <!-- Input de email -->
            <input *ngIf="field.type === 'email'" type="email"
            class="form-control" [formControlName]="field.name">

            <!-- Input numerico -->
            <input *ngIf="field.type === 'number'" type="number" class="form-control" [formControlName]="field.name"
                (input)="onKeyUp($event, field)" (keydown)="preventInvalidInput($event)">

            <!-- Input de fecha -->
            <input *ngIf="field.type === 'date'" type="date" class="form-control" [formControlName]="field.name">

            <!-- Select -->
            <select *ngIf="field.type === 'select'" class="form-select" [formControlName]="field.name"
                (change)="field.change ? onSelectChange($event, field) : null">
                <option *ngFor="let option of field.options" [value]="option.value" [hidden]="option.value === null">{{ option.label }}</option>
            </select>

            <!-- Textarea -->
            <textarea *ngIf="field.type === 'textarea'" class="form-control" [formControlName]="field.name"
                (keyup)="onKeyUp($event, field)"></textarea>

            <!-- Editor de Texto -->
            <ckeditor *ngIf="field.type === 'editor'" [editor]="Editor" [formControlName]="field.name"
                (change)="onKeyUp($event, field)"></ckeditor>

            <div *ngIf="field.type === 'file'">
                <!-- Vista previa de la imagen si existe -->
                <div *ngIf="field.value !== 'https://tg-alumnos-v2.tgconsulting.online/archivos/category_images/' &&
                            field.value !== null && !changeImage[i]" class="image-preview">
                    <img [src]="field.value" alt="Imagen previa" width="50" height="50">
                    <button type="button" class="btn btn-sm btn-secondary" (click)="toggleImageChange(i)">Cambiar
                        Img.</button>
                    <!-- Botón para eliminar la imagen, si el campo tiene la propiedad categoryModal -->
                    <button type="button" class="btn btn-sm btn-danger"
                        (click)="deleteImage(i, field.name, field.label)">Eliminar Imagen</button>
                </div>

                <!-- Input para cargar nueva imagen -->
                <div *ngIf="changeImage[i] || field.value === null || 
                field.value === 'https://tg-alumnos-v2.tgconsulting.online/archivos/category_images/'" class="image-upload">
                    <input type="file" class="form-control" [attr.data-index]="i" (change)="onFileChange($event, field.label, i, field.name)"
                        accept="image/*">
                    <button *ngIf="field.value !== 'https://tg-alumnos-v2.tgconsulting.online/archivos/category_images/' &&
                    field.value !== null" type="button" class="btn btn-sm btn-warning"
                        (click)="restorePreviousImage(i, field.value, field.name)">
                        Restaurar Img. Anterior
                    </button>
                    <button *ngIf="hasFileSelected[i]" type="button"
                        class="btn btn-sm btn-danger" (click)="deleteSelectedImage(i)">
                        Eliminar Img. Seleccionada
                    </button>
                </div>
            </div>

            <!-- Mostrar feedback de error -->
            <div *ngIf="form.get(field.name) && form.get(field.name).invalid && form.get(field.name).touched"
                class="invalid-feedback">
                <span *ngIf="form.get(field.name).hasError('required')">{{ field.errorMessage }}</span>
            </div>

            <div style="display: flex; flex-direction: column;">
                <span class="text-danger"
                      *ngIf="(field.type === 'text' || field.type === 'editor' || field.type === 'number' || field.type === 'textarea' || field.type === 'password' || field.type === 'password') && form.get(field.name).hasError('noLetters')">
                  El campo debe contener letras
                </span>
                <span class="text-danger"
                      *ngIf="(field.type === 'text' || field.type === 'editor' || field.type === 'number' || field.type === 'textarea' || field.type === 'password') && form.get(field.name).hasError('characterLimitExceeded')">
                  El campo no puede exceder los {{ getCurrentCharacterLimit(field.characterLimit) }} caracteres
                </span>
                <span class="text-danger"
                      *ngIf="(field.type === 'text' || field.type === 'editor' || field.type === 'number' || field.type === 'textarea' || field.type === 'password') && form.get(field.name).hasError('characterMinNotMet')">
                  El campo debe contener al menos {{ field.characterMin }} caracteres
                </span>
                <span class="text-danger"
                      *ngIf="(field.type === 'text' || field.type === 'editor' || field.type === 'number' || field.type === 'textarea' || field.type === 'password') && form.get(field.name).hasError('moreSpacesThanCharacters')">
                  El contenido tiene más espacios que letras
                </span>
                <span class="text-danger"
                      *ngIf="(field.type === 'text' || field.type === 'editor' || field.type === 'number' || field.type === 'textarea' || field.type === 'password') && form.get(field.name).hasError('specialCharactersNotAllowed')">
                  El campo no puede contener caracteres especiales ni espacios
                </span>
              </div>                      
        </div>

        <!-- Deshabilitar el botón de guardar si el formulario es inválido o tiene errores -->
        <button type="submit" class="btn btn-primary"
            [disabled]="form.invalid || hasFieldErrors() || saving">Guardar</button>

    </form>
</div>
