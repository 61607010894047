<app-sidebar-menu></app-sidebar-menu>



<div class="container">
  <br>
  <h3>Categorias de Examen</h3>
  <br>
  <div class="card-container">
      <div class="card" *ngFor="let categoria of categorias">
          <img src="assets/img/categorias/{{categoria.image}}.jpg" class="card-img-top" alt="...">
          <div class="card-body">
              <h5 class="card-title">{{categoria.category}}</h5>
              <div class="quizzes-and-button">
                <p class="card-text">Cuestionarios</p>
                <div class="btn-ver-examenes">
                  <button class="btn btn-sm btn-outline-success"
                  *ngIf="rolUsuario !== 'student'" (click)="abrirModalVerExamenes(
                   categoria.category,categoria.duration, categoria.totalQuestions, verExamenes, categoria.slug)">
                    <i class='bx bx-show'></i> Ver examenes
                  </button>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- MODAL VER EXAMENES POR CATEGORIA -->
<ng-template #verExamenes role="document" let-modal>
  <div >
    <div class="modal-header">
      <h5 class="modal-title" >{{titulo}}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cerrarModal()"></button>
    </div>
    <div class="modal-body" >
      <table *ngIf="!isMobile && !obteniendoExamenes" id="basic-datatable" class="table table-bordered text-center">
        <thead class="table-light">
          <tr>
            <th>Titulo</th>
            <th>Duracion</th>
            <th>Total de preguntas</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody >
          <tr *ngFor="let examen of examenesCategoria">
            <td>{{examen.title}}</td>
            <td>{{examen.duration + ' Minutos'}}</td>
            <td>{{examen.totalQuestions}}</td>
            <td><button class="btn btn-sm btn-primary" (click)="irAHacerExamen(examen.slug, examen.duration)">
              <i class="bx bx-pencil"></i>Hacer examen</button>
            </td>
          </tr>
        </tbody>
      </table>
        <!-- Estructura para dispositivos móviles por historia-->
     <div *ngIf="isMobile">
      <div *ngFor="let exam of examenesCategoria" class="accordion">
          <div class="accordion-header" (click)="toggleExpand(exam)">
              <span>{{exam.title}}</span>
              <button class="btn btn-sm btn-secondary">
                  <i [class]="exam.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
              </button>
          </div>
          <div class="accordion-content" *ngIf="exam.isExpanded">
              <strong>Duracion:</strong>{{(exam.duration + ' Minutos')}}<br>
              <strong>Total de preguntas:</strong>{{exam.totalQuestions}}<br>
              <strong>Accion:</strong><label class="buttons-label" (click)="irAHacerExamen(exam.slug, exam.duration)">Hacer examen</label><br>
          </div>
      </div>
      <span style="margin-top: 10px;" *ngIf="examenesCategoria.length === 0">Actualmente no hay examenes</span>
  </div>
    </div>
  </div>
</ng-template>
