<div class="cart-area ptb-100">
  <div class="container">
    <div class="title-container">
      <h3>{{title}}</h3>
    </div>
    <div class="header">
      <div class="button-container" *ngIf="buttonText !== ''">
        <button class="btn btn-primary" (click)="onEdit(null)">{{buttonText}}</button>
      </div>
      <div class="search-container">
        <input type="text" placeholder="Buscar aqui..." class="input-search" (keyup)="filterChange($event)">
      </div>
      <div class="registers-container">
        <span> Mostrar </span>
        <select id="pageSizeSelect" (change)="changePageSize($event)">
          <option value="5">5</option>
          <option value="10" selected>10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span> Registros </span>
      </div>
    </div>
    <div class="table-container">
      <table class="table table-bordered" *ngIf="!isMobile">
        <thead>
          <tr>
            <th *ngFor="let column of columns">{{ column.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of filteredData | paginate: currentPage : pageSize; let i = index">
            <td *ngFor="let column of columns">
              <ng-container [ngSwitch]="column.type">
                <!-- Para columnas de tipo 'image' -->
                <ng-container *ngSwitchCase="'image'">
                  <img *ngIf="item[column.key] !== 'https://tg-alumnos-v2.tgconsulting.online/archivos/category_images/'" 
                  [src]="item[column.key]" alt="Image" style="width: 50px; height: auto;">
                  <span *ngIf="item[column.key] === 'https://tg-alumnos-v2.tgconsulting.online/archivos/category_images/'">
                    No hay imagen
                  </span>
                </ng-container>

               <!-- Para columnas de tipo 'actions' -->
<ng-container *ngSwitchCase="'actions'">
  <div class="dropdown">
      <button class="btn btn-link dropdown-toggle" type="button" (click)="toggleMenu(item.id, $event,i)" aria-expanded="isMenuOpen[item.id]">
          &#x22EE; <!-- Tres puntos verticales -->
      </button>
      <ul class="dropdown-menu" [ngClass]="{'show': isMenuOpen[item.id]}" [id]="'dropdown-menu-'+i" (mouseleave)="closeMenu(item.id)">
          <li *ngIf="item.actions.buttonOne !== null">
              <button class="dropdown-item" (click)="primaryAction(item.id)">{{item.actions.buttonOne}}</button>
          </li>
          <li *ngIf="item.actions.buttonTwo !== null">
              <button class="dropdown-item" (click)="onEdit(item)">{{item.actions.buttonTwo}}</button>
          </li>
          <li *ngIf="item.actions.buttonThree !== null">
              <button class="dropdown-item" (click)="tertiaryAction(item)">{{item.actions.buttonThree}}</button>
          </li>
          <li *ngIf="item.actions.buttonFour !== null">
              <button class="dropdown-item" (click)="quaternaryAction(item)">{{item.actions.buttonFour}}</button>
          </li>
      </ul>
  </div>
</ng-container>

                <!-- Para columnas normales -->
                <ng-container *ngSwitchDefault>
                  {{ item[column.key] }}
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
       <!-- Estructura para dispositivos móviles -->
       <div *ngIf="isMobile">
          <div *ngFor="let itemMovil of filteredData | paginate: currentPage : pageSize" class="accordion">
            <div class="accordion-header" (click)="toggleExpand(itemMovil)">
              <!-- Columna dinámica para el título del acordeón -->
            <!-- Modificar el span -->
            <span>{{ itemMovil[getAccordionColumn()?.key] }}</span>
            <button class="btn btn-sm btn-secondary">
              <i [class]="itemMovil.isExpanded ? 'bx bx-chevron-up' : 'bx bx-chevron-down'"></i>
            </button>
          </div>

          <div class="accordion-content" *ngIf="itemMovil.isExpanded">
            <!-- Recorrer columnas dinámicamente dentro del acordeón -->
            <div *ngFor="let column of columns">
              <ng-container [ngSwitch]="column.type">
                <!-- Para columnas de tipo 'image' -->
                <ng-container *ngSwitchCase="'image'">
                  <strong>{{ column.title }}:</strong>
                  <img *ngIf="itemMovil[column.key]" [src]="itemMovil[column.key]" alt="Image" style="width: 50px; height: auto;"><br>
                </ng-container>

                <!-- Para columnas de tipo 'actions' -->
                <ng-container *ngSwitchCase="'actions'">
                  <div *ngIf="column.buttonOne !== null">
                    <strong>Acción:</strong> <span style="text-decoration: underline; color: dodgerblue;"
                  (click)="primaryAction(itemMovil.id)">
                    {{ column.buttonOne }}</span><br>
                  </div>
                  <div *ngIf="column.buttonTwo !== null">
                    <strong>Acción:</strong> <span style="text-decoration: underline; color: dodgerblue;"
                    (click)="onEdit(itemMovil)">
                      {{ column.buttonTwo }}</span><br>
                  </div>
                  <div *ngIf="column.buttonThree !== null">
                    <strong>Acción:</strong> <span style="text-decoration: underline; color: dodgerblue;"
                    (click)="tertiaryAction(itemMovil)">
                      {{ column.buttonThree }}</span><br>
                  </div>
                  <div *ngIf="column.buttonFour !== null">
                    <strong>Acción:</strong> <span style="text-decoration: underline; color: dodgerblue;"
                    (click)="quaternaryAction(itemMovil)">
                      {{ column.buttonFour }}</span><br>
                  </div>
                </ng-container>

                <!-- Para columnas normales -->
                <ng-container *ngSwitchDefault>
                  <strong>{{ column.title }}:</strong> {{ itemMovil[column.key] }}<br>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
</div>

          <!-- Paginación -->
          <app-paginacion *ngIf="filteredData.length >= pageSize" 
            [paginaActual]="currentPage"
            [elementosPorPagina]="pageSize" 
            [totalElemetos]="filteredData.length"
            (cambioPagina)="changePage($event)">
          </app-paginacion>
          
    <span *ngIf="data.length === 0" style="font-weight: 500;">*Actualmente no hay registros</span>
    </div>
  </div>
</div>
