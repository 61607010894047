import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { SidebarMenuComponent } from 'src/app/components/common/sidebar-menu/sidebar-menu.component';
import { categoriesDTO } from 'src/app/interface/material';
import { ExamenesService } from 'src/app/service/examenes.service';
import { PaginatePipe } from "../../../../../pipes/paginate.pipe";
import { PaginacionComponent } from "../../../../common/paginacion/paginacion.component";
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categorias',
  standalone: true,
  providers:[BsModalService],
  imports: [SidebarMenuComponent, CommonModule, PaginatePipe, PaginacionComponent],
  templateUrl: './categorias.component.html',
  styleUrl: './categorias.component.scss',
})

export class CategoriasComponent {

  rolUsuario: any;
  titulo: any;
  duracion: any;
  totalPreguntas: any;

  currentPageTable: number;
  pageSize: number;
  modalRef: any;
  
  examenesCategoria: any = [];
  categorias: any[]=[];
  themeService: any;
  item: any;
  isMobile:boolean
  
  constructor(private examenesService: ExamenesService, private modalService:BsModalService,
    private router: Router, private cdr: ChangeDetectorRef ){
      this.checkScreenSize()
    }

     // Usar HostListener para escuchar los cambios de tamaño de la ventana
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 767;
    console.log('isMobile:', this.isMobile);
    this.cdr.markForCheck(); // Notificar a Angular que revise los cambios
  }

  toggleExpand(item: any) {
    item.isExpanded = !item.isExpanded;
  }
  
  ngOnInit(): void {
    this.obtenerCategorias()
  }

  obtenerCategorias() {
    this.examenesService.obtenerCategorias(localStorage.getItem("idUsuario")).subscribe((datosInicio:categoriesDTO) => {
      this.categorias = datosInicio.categories
      console.log(this.categorias, 'api alumno');
    }, error => {
      console.error(error);
    });
  }
  obteniendoExamenes:boolean = false
  obtenerExamenes(slug: string) {
    this.obteniendoExamenes = true
    this.examenesService.obtenerExamenes(slug).subscribe(datosInicio => {
      console.log(datosInicio, 'api examenes');
      this.examenesCategoria = datosInicio;
      this.obteniendoExamenes = false
    }, error => {
      console.error(error);
    });
  }

  abrirModalVerExamenes(titulo: any,duracion: any,totalPreguntas: any, verExamenes:any,slug:string) {
    this.titulo = titulo;
    this.duracion = duracion;
    this.totalPreguntas = totalPreguntas;
    this.modalRef = this.modalService.show(verExamenes, { class: 'modal-lg', backdrop: 'static' });
    this.obtenerExamenes(slug);
  }

  irAHacerExamen(slug:string, duration:string) {
    this.cerrarModal()
    this.router.navigate(['/hacer-examen', slug], { state: { duration } });
  }

  onPageChange(newPage: number) {
    this.currentPageTable = newPage;
  }
  
  cerrarModal(){
    this.modalRef.hide()
  }
}
