<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
  [columns]="columns"
  [data]="instrucciones"
  title="Instrucciones"
  buttonText="Crear"
  (onOpenModal)="abrirModal($event)"
  (onFilterChange)="filterInstrucciones($event)">
</app-examenes-admin>

<ng-template #dynamicModal>
    <app-dynamic-modal
      [modalTitle]="modalTitle"
      [fields]="modalFields"
      [initialData]="initialData"
      [saving]="saving"
      (formSubmit)="guardarInstrucciones($event)"
      (closeModal)="cerrarModal()">
    </app-dynamic-modal>
  </ng-template>
