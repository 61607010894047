<!-- CONTENEDOR DE EXAMEN -->
<app-sidebar-menu></app-sidebar-menu>
<div class="container">
    <div class="quiz">
        <div class="body">
            <div class="header">
                <h4>{{datosRevision.quizTitle}}</h4>
                <h4>{{datosRevision.examStatus === 'pass' ? 'Aprobado' : 'No aprobado'}}</h4>
            </div>

            <div class="question-container">
                <h5>{{ datosRevision?.quizTitle }} pregunta {{ currentQuestionIndex + 1 }} de {{
                    datosRevision?.questions.length }}</h5>

                <div class="question" *ngIf="datosRevision?.questions?.length > 0">
                    <span class="question-span"
                        [innerHTML]="datosRevision.questions[currentQuestionIndex]?.questionText | decodeHtml"></span>
                    <div class="question-image-container" *ngIf="datosRevision.questions[currentQuestionIndex]?.questionFile !== ''">
                        <img class="question-image" [src]="urlImgs + datosRevision.questions[currentQuestionIndex]?.questionFile" alt="">
                    </div>
                    <div class="answers">
                        <div *ngFor="let option of datosRevision.questions[currentQuestionIndex]?.answers">
                            <label class="option"
                            [ngClass]="{
                               'correct-answer': isCorrectAnswer(option.index, datosRevision.questions[currentQuestionIndex]?.correctAnswer),
                               'wrong-answer': datosRevision.questions[currentQuestionIndex]?.userAnswer?.includes(option.index)
                                               && !isCorrectAnswer(option.index, datosRevision.questions[currentQuestionIndex]?.correctAnswer)
                            }">
                         <input *ngIf="datosRevision.questions[currentQuestionIndex]?.questionType  === 'checkbox'" class="radio-button" type="checkbox"
                             name="answer-{{datosRevision.questions[currentQuestionIndex]?.questionId}}"
                             [value]="option.index"
                             [checked]="datosRevision.questions[currentQuestionIndex]?.userAnswer?.includes(option.index)"
                             disabled>
                        <input *ngIf="datosRevision.questions[currentQuestionIndex]?.questionType  === 'radio'" class="radio-button" type="radio"
                             name="answer-{{datosRevision.questions[currentQuestionIndex]?.id}}" [value]="option.index"
                             [checked]="datosRevision.questions[currentQuestionIndex]?.userAnswer?.includes(option.index)" disabled>
                             {{option.option_value.option_value}}
                         {{ option.option_value }}
                         <div class="option-image-container" *ngIf="option.file_name !== ''">
                            <img [src]="urlImgs + option.file_name" alt="">
                         </div>
                     </label>


                        </div>
                    </div>
                </div>
            </div>
            <!-- Mostrar la explicación sin formato HTML -->
            <div class="explanation" *ngIf="datosRevision.questions[currentQuestionIndex]?.explanation !== ''">
                <span>Explicación: {{ stripHtmlTags(datosRevision.questions[currentQuestionIndex]?.explanation | decodeHtml)  }}</span>
            </div>

            <div class="footer">
                <div class="primary-buttons">
                    <button class="btn btn-sm btn-success" (click)="irAPreguntaAnterior()">
                        <i class="bx bx-arrow-from-right"></i>Anterior
                    </button>
                    <button class="btn btn-sm btn-success" (click)="irAPreguntaSiguiente()">
                        <i class="bx bx-arrow-from-left"></i>Siguiente
                    </button>
                    <button class="btn btn-sm btn-danger" (click)="abandonarExamen()"><i
                        class="bx bx-log-out"></i>Salir</button>
                </div>
            </div>
        </div>

        <div class="right-panel">
            <div class="counter">
                <div class="time-span">
                    <span style="font-weight: bold;"><i class="bx bx-time"></i>Tiempo límite:</span>
                    <span>
                      {{ datosRevision.questions[currentQuestionIndex]?.timeLimit !== undefined
                        ? formatTime(datosRevision.questions[currentQuestionIndex]?.timeLimit)
                        : '00:00:00' }}
                    </span>
                  </div>
                  <div class="time-span">
                    <span style="font-weight: bold;"><i class="bx bx-time"></i>Tiempo tomado:</span>
                    <span>
                      {{ datosRevision.questions[currentQuestionIndex]?.timeSpent !== undefined
                        ? formatTime(datosRevision.questions[currentQuestionIndex]?.timeSpent)
                        : '00:00:00' }}
                    </span>
                  </div>

                <span><i class="bx bxs-circle" style="color: #89faa3;"></i> Respuesta Correcta</span>
                <span><i class="bx bxs-circle" style="color: #fab3b9;"></i> Respuesta Incorrecta</span>
                <span><i class="bx bxs-arrow-to-right"></i> Si no hay <i class="bx bxs-circle"
                        style="color: #fab3b9;"></i> significa que la respuesta del
                    usuario fue correcta.</span>
                <span><i class="bx bxs-arrow-to-right"></i> Si no hay respuesta marcada significa que el usuario no
                    respondió la pregunta.</span>

            </div>
        </div>
    </div>
</div>
