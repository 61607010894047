import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesService } from 'src/app/service/examenes.service';
import { PaginacionComponent } from "../../../../common/paginacion/paginacion.component";
import { CommonModule } from '@angular/common';
import { PaginatePipe } from "../../../../../pipes/paginate.pipe";
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { Router } from '@angular/router';
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalPreguntaService } from 'src/app/service/util/modal-pregunta.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-banco-preguntas',
  standalone: true,
  imports: [PaginacionComponent, CommonModule, PaginatePipe, ExamenesAdminComponent, SidebarMenuComponent, DynamicModalComponent],
  templateUrl: './banco-preguntas.component.html',
  styleUrl: './banco-preguntas.component.scss',
  providers: [BsModalService, ToastrService]
})
export class BancoPreguntasComponent implements OnInit {
  columns = [
    { key: 'name', title: 'Materia', accordionKey: true },
    { key: 'subjectCode', title: 'Codigo' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: 'Ver Preguntas',
       buttonTwo: 'Agregar', buttonThree: null, buttonFour: null }
  ];

  materias: any[] = []
  originalMaterias: any[] = []
  pageSize: number = 10
  currentPage: number = 1
  searchTerm: string = ''
  questionTypeValueSelected: any
  // MODAL CREAR MATERIA
  modalTitle: string = 'Agregar Materia'
  modalFields: any[] = []
  originalModalFields: any[] = [];
  private camposOpcionesFormulario: any[] = [];
  modalRef: BsModalRef
  initialData: any = null
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  esMateria: boolean
  idMateriaGuardar: number
  currentFieldValues: { fieldName: string, value: any }[] = [];
  selectedFile: File | null = null;
  saving: boolean = false
  seRestauroImg: boolean = false
  imagenGlobalAnterior: string
  //MODAL AGREGAR PREGUNTA
  temasPorMateria: any[] = []

  constructor(private examenesService: ExamenesService, private router: Router,
    private modalService: BsModalService, private toastrService: ToastrService,
    private modalPreguntaService: ModalPreguntaService) {
  }
  ngOnInit(): void {
    this.obtenerPreguntas()
    this.examenesService.restoreImage$.subscribe((estado: boolean) => {
      this.seRestauroImg = estado;
    });
  }

  obtenerPreguntas() {
    this.examenesService.obtenerPreguntasTabla().subscribe(
      (response: any[] = []) => {
        console.log(response, 'materias')
        this.materias = response
        this.originalMaterias = [...response];
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerTemasPorMateria(subjectId: any) {
    console.log(subjectId, 'identro');

    if (subjectId) {
        this.examenesService.obtenerTemasPorMateria(parseInt(subjectId.selectedValue?
          subjectId.selectedValue : subjectId)).subscribe(
            (response: any[] = []) => {
                this.temasPorMateria = response;
                console.log(this.temasPorMateria, 'temas');

                // Actualizar las opciones de temas en el modal
                const topicField = this.modalFields.find(field => field.name === 'topic');
                if (topicField) {
                    let totalOptions: any[] = this.temasPorMateria.map(option => ({
                      value: option.id,
                      label: option.topicName
                  }))
                  topicField.options = totalOptions
                }
            },
            (error: any) => {
                console.error('Error obteniendo temas por materia', error);
            }
        );
    }
}

  abrirModal(datos: any) {
    if (datos === null) {
      this.esMateria = true
      this.modalTitle = datos ? 'Editar Materia' : 'Nueva Materia';
      this.modalFields = [
        {
          name: 'title', type: 'text', label: 'Titulo de Materia*',
          required: true, errorMessage: 'El titulo es obligatorio.',
          onlyNumbers: false, characterLimit: 50, characterMin: 3
        },
        {
          name: 'code', type: 'textarea', label: 'Codigo de Materia*',
          required: true, errorMessage: 'El codigo es obligatorio.',
          characterLimit: 10, onlyNumbers: false
        }
      ];
    } else {
      this.esMateria = false
      this.obtenerTemasPorMateria(datos.id)
      this.modalTitle = 'Cargar Pregunta';
      this.idMateriaGuardar = datos ? datos.id : null
      this.imagenGlobalAnterior = datos ? datos.questionFile : null
      this.modalFields = [
        {
          name: 'topic', type: 'select', label: 'Tema*',
          required: true, errorMessage: 'El tema es obligatorio.'
        },
        {
          name: 'question', type: 'editor', label: 'Pregunta*', characterMin: 10,
          required: true, errorMessage: 'La pregunta es obligatoria.',
          onlyNumbers:false
        },
        {
          name: 'questionType', type: 'select', label: 'Tipo de pregunta*', change: true,
          required: true, errorMessage: 'El tipo de pregunta es obligatorio.',
          options: this.modalPreguntaService.typeQuestionOptions.map(option => ({ value: option.id, label: option.label }))
        },
        {
          name: 'file', type: 'file', label: 'Cargar', value: null, required: false, preguntaModal:true,
        },
        {
          name: 'difficulty', type: 'select', label: 'Nivel de dificultad*',
          required: true, errorMessage: 'El nivel de dificultad es obligatorio..',
          options: this.modalPreguntaService.difficultyLevelOptions.map(option => ({ value: option.id, label: option.label }))
        },
        {
          name: 'tips', type: 'text', label: 'Tips', onlyNumbers:false,
          placeholder: 'Sugerencia para la pregunta', required: false, characterMin: 3
        },
        {
          name: 'description', type: 'editor', label: 'Explicacion', required: false, 
          onlyNumbers:false, characterMin: 15
        },
        {
          name: 'percentage', type: 'number', label: 'Puntuaciones*',
          required: true, errorMessage: 'La puntuacion es obligatoria.', value: 1,
          characterLimit: 3
        },
        {
          name: 'time', type: 'number', label: 'Tiempo a invertir (seg)*',
          required: true, errorMessage: 'El tiempo a invertir es obligatorio.', placeholder:'Exprese valor en segundos',
          characterLimit:5
        },
      ];
    }
    this.originalModalFields = [...this.modalFields]
    this.initialData = datos ? this.modalFields : null;
    this.modalRef = this.modalService.show(this.dynamicModalTemplate, { class: !this.esMateria ? 'modal-lg' : 'modal-md', backdrop:'static' });
  }

  handleQuestionTypeChange(event: any) {
    const selectedType = event.selectedValue
    const fieldName = event.fieldName;

    if (fieldName === 'questionType') {
      this.questionTypeValueSelected = selectedType;
      this.originalModalFields = this.originalModalFields.filter(field =>
        field.name !== 'totalOptions' &&
        field.name !== 'totalWhiteAnswers' &&
        field.name !== 'totalQuestions'
      );

      let newModalFields = [];

      // Añadir campos según el tipo de pregunta seleccionada
      switch (selectedType) {
        case 'radio':
        case 'checkbox':
        case 'match':
          this.modalPreguntaService.generateTotalOptions(newModalFields);
          break;
        case 'blanks':
          this.modalPreguntaService.generateTotalWhiteAnswers(newModalFields);
          break;
        case 'para':
        case 'video':
        case 'audio':
          this.modalPreguntaService.generateTotalQuestionsAndTotalOptions(newModalFields);
          break;
        default:
          break;
      }

      const newModalFieldsTemp = [...this.originalModalFields,...newModalFields];
      this.modalFields = [...newModalFieldsTemp];
      this.originalModalFields = [...newModalFieldsTemp]
    }else if (fieldName === 'totalOptions') {
      if (this.questionTypeValueSelected === 'radio') {
        this.modalPreguntaService.generateOptionsWithImage(selectedType, []).subscribe(
          (resultModalFields: any[] = []) => {
            this.camposOpcionesFormulario = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );

      } else if (this.questionTypeValueSelected === 'checkbox') {
        this.modalPreguntaService.generateOptionsWithMultipleAnswers(selectedType, []).subscribe(
          (resultModalFields: any[] = []) => {
            this.camposOpcionesFormulario = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      } else if (this.questionTypeValueSelected === 'match') {
        this.modalPreguntaService.generateMatchQuestions(selectedType, this.modalFields).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      } else if (this.questionTypeValueSelected === 'para' || this.questionTypeValueSelected === 'video'||
        this.questionTypeValueSelected === 'audio') {
        console.log('entro en totaloptions')
        this.modalPreguntaService.generateQuestionsAndOptions(selectedType, 'options', this.modalFields).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      }
      const newModalFieldsTemp = [...this.originalModalFields,...this.camposOpcionesFormulario];
      this.modalFields = [...newModalFieldsTemp];
    }
    else if (fieldName === 'totalQuestions') {
      if (this.questionTypeValueSelected === 'para' || this.questionTypeValueSelected === 'video'||
        this.questionTypeValueSelected === 'audio') {
        console.log('entro en totalquestions')
        this.modalPreguntaService.generateQuestionsAndOptions(selectedType, 'questions', this.modalFields).subscribe(
          (resultModalFields: any[] = []) => {
            this.modalFields = resultModalFields
          },
          (error: any) => {
            console.error('Error obteniendo materias', error);
          }
        );
      }
    }
    else if (fieldName === 'totalWhiteAnswers') {
      this.modalPreguntaService.generateWhiteAnswers(selectedType, this.modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          this.modalFields = resultModalFields
        },
        (error: any) => {
          console.error('Error obteniendo materias', error);
        }
      );
    }
    else if (fieldName === 'correctAnswersNumber') {
      this.modalPreguntaService.generateCorrectAnswerFields(selectedType, this.modalFields).subscribe(
        (resultModalFields: any[] = []) => {
          this.modalFields = resultModalFields;
        },
        (error: any) => {
          console.error('Error generando respuestas correctas', error);
        }
      );
    }
  }

  // Método que captura la información al guardar en el modal
  onFormSubmit(data: any) {
    if (this.esMateria) {
      this.guardarMateria(data);
    } else {
      this.guardarPregunta(data)
    }
  }

  guardarMateria(data: any) {
    this.saving = true
    console.log(data)
    const request: any = {
      id: null,
      title: data.title,
      code: data.code,
    }
    this.examenesService.guardarMateria(request).pipe(catchError(error => {
        this.saving = false
        this.toastrService.error("No se pudo guardar la materia", "Error")
        return throwError(error)
    })).subscribe(
      (result) => {
        this.saving = false
        this.toastrService.success("Se guardo la materia correctamente", "Exito")
        this.cerrarModal()
        this.obtenerPreguntas()
      }
    );
  }

  private fileMap:Map<string, File> = new Map<string, File>();
  private fileNewMap:Map<string, File> = new Map<string, File>();
  private fileListaMap:Map<number, Map<string, File>> = new Map<number, Map<string, File>>();
  onFileSelected(file: any) {
    const archivo = file.archivo
    if(file.id === 0){
        this.fileMap.set(archivo.name, archivo)
        this.fileListaMap.set(file.id, this.fileMap)
    }else{
      this.fileNewMap = new Map<string, File>();
      if(archivo === null){
        this.fileListaMap.set(file.id, null)
      }else{
        this.fileNewMap.set(archivo.name, archivo);
        this.fileListaMap.set(file.id, this.fileNewMap)
      }
  }
  }

  guardarPregunta(data: any) {
    this.saving = true
    let optionsArray = [];
    const questionType = data.questionType
    const totalElements = parseInt(data.totalOptions ? data.totalOptions : data.totalWhiteAnswers, 10);
    let correctAnswers
    let correctAnswersMultiple:any[] = []
    let totalCorrectAnswers
    let marks:number
    let optionRequest: any = {};
    for (let i = 1; i <= totalElements; i++) {
      switch (questionType) {
        case 'radio': // Pregunta de opción única
        let filePath = data[`optionImage${i}`];
        let fileName = filePath ? filePath.split('\\').pop() : '';
          optionRequest = {
            option_value: data[`option${i}`] || "", // El valor de la opción
            has_file: fileName ? 1 : 0, // Si hay archivo, 1; si no, 0
            file_name: this.fileMap.get(fileName) ? fileName : "",
          };

          correctAnswers = data.answerNumber
          totalCorrectAnswers = 1
          marks = 1
          break;

        case 'checkbox': // Pregunta con múltiples opciones
         let filePathCheck = data[`optionImage${i}`];
         let fileNameCheck = filePathCheck ? filePathCheck.split('\\').pop() : '';

          optionRequest = {
            option_value: data[`option${i}`] || "", // El valor de la opción
            has_file: fileNameCheck ? 1 : 0, // Si hay archivo, 1; si no, 0
            file_name: this.fileMap.get(fileNameCheck) ? fileNameCheck : "",
          };
          totalCorrectAnswers = parseInt(data.correctAnswersNumber)
          const value = data[`correctAnswer${i}`];
          if(value !== undefined && value !== null && value !== ''){
            correctAnswersMultiple?.push({ answer:  value});
          }
          break;

        case 'blanks': // Pregunta tipo completar frase
          optionRequest = {
            optionValue: data[`whiteAnswer${i}`] || "",
            hasFile: 0,
            fileName: "",
          };
          totalCorrectAnswers = 1
          correctAnswers = 0
          break;

        case 'match': // Pregunta de tipo unir preguntas con respuesta
          optionRequest = {
            optionValue: data[`leftOption${i}`] + data[`rightOption${i}`] || "",
            hasFile: 0,
            fileName: "",
          };
          totalCorrectAnswers = parseInt(data.totalOptions, 10);
          for (let j = 1; j <= totalCorrectAnswers; j++) {
            correctAnswers.push({ answer: data[`answer${j}`] });
          }
          break;

        case 'para':
        case 'video':
        case 'audio':
          optionRequest = {
            optionValue: data[`leftOption${i}`] + data[`rightOption${i}`] || "",
            hasFile: 0,
            fileName: "",
          };
          totalCorrectAnswers = parseInt(data.totalOptions, 10);
          for (let j = 1; j <= totalCorrectAnswers; j++) {
            correctAnswers.push({ answer: data[`answer${j}`] });
          }
          break;

        default:
          console.warn("Tipo de pregunta no reconocido:", questionType);
          break;
      }
      optionsArray.push(optionRequest);
    }
    const request = {
      subjectId: this.idMateriaGuardar,
      question: data.question,
      questionType: data.questionType,
      status: 1,
      topic: data.topic,
      totalOptions: data.totalOptions,
      difficultyLevel: data.difficulty,
      timeToInvest: data.time,
      tips: data.tips || '',
      explanation: data.description || '',
      totalAnswers: optionsArray.length,
      totalCorrectAnswers: totalCorrectAnswers,
      hint: '',
      marks: data.marks? data.marks : 1,
      questionFileIsUrl: 0,
      questionFile:'',
      file:data.file ? data.file.split('\\').pop() : '',
      correctAnswers: correctAnswersMultiple.length > 0? JSON.stringify(correctAnswersMultiple) : correctAnswers,
      options: optionsArray
    };
    console.log(request)
    const listFile = []
    const firstList = this.fileListaMap.get(1);
    const secondList = this.fileListaMap.get(0);
    if(firstList){
      firstList.forEach((file, key) => {
        if(this.seRestauroImg === true){
          listFile.push(this.imagenGlobalAnterior);
          request.file = this.imagenGlobalAnterior
        }
        else if(this.seRestauroImg === false && this.imagenGlobalAnterior !== null && 
          this.imagenGlobalAnterior !== data.file){
            listFile.push(file);
          request.file = key
        }else if (this.seRestauroImg === false && this.imagenGlobalAnterior !== null &&
           this.imagenGlobalAnterior === data.file){
            listFile.push(this.imagenGlobalAnterior);
          request.file = this.imagenGlobalAnterior
        }else if(this.seRestauroImg === false && this.imagenGlobalAnterior === null){
          listFile.push(file);
          request.file = key
        }
      });
  }else if(data.file === null && !this.seRestauroImg){
    request.file = null
  }
    if(secondList){
        secondList.forEach((file, key) => {
            listFile.push(file);
        });
    }
    this.examenesService.guardarPregunta(request, listFile.length > 0 ? listFile : []).subscribe(
      (result) => {
        this.saving = false
        this.toastrService.success("Se guardó la pregunta correctamente", "Éxito");
        this.cerrarModal();
        this.obtenerPreguntas();
      },
      (error: any) => {
        this.saving = false
        this.toastrService.error("No se pudo guardar la pregunta", "Error");
        console.error(error)
      }
    );
  }

  cerrarModal() {
    this.modalRef.hide()
  }

  filterPreguntas(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.materias = this.originalMaterias.filter(mat =>
      mat.name.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1;
  }

  verPreguntas(subjectId: number) {
    const materia = this.materias.find(materia => materia.id === subjectId);
    const materiaName = materia ? materia.name : 'Materia no encontrada';
    console.log(subjectId, 'materia desde banco')
    this.router.navigate(['/ver-preguntas', subjectId, materiaName]);
  }

  changePageSize(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
  }
}
