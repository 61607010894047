<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
[columns]="columns"
[data]="usuarios"
title="Usuarios"
buttonText="Nuevo Usuario"
(onMenuToggle)="onMenuToggle($event)"
(onOpenModal)="abrirModal($event)"
(onPrimaryAction)="irAReportes($event)"
(onTertiaryAction)="irAEdicionExamenes($event)"
(onQuaternaryAction)="eliminarUsuario($event, modalEvaluacion)" >
</app-examenes-admin>

<ng-template #dynamicModal>
    <app-dynamic-modal
      [modalTitle]="modalTitle"
      [fields]="modalFields"
      [initialData]="initialData"
      [saving]="saving"
      (closeModal)="cerrarModal()"
      (formSubmit)="guardarUsuario($event)"
      (fileChange)="onFileSelected($event)">
    </app-dynamic-modal>
  </ng-template>

  <!-- Modal de Confirmación -->
  <ng-template #modalEvaluacion role="document" let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel" style="font-size: 16px;">Confirmacion</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
     <div class="modal-text">
      <p>Esta seguro? No se podra recuperar este registro.</p>
     </div>
     <div class="confirm-buttons">
      <button class="btn btn-success" (click)="confirmDelete()"><i class="bx bx-check-circle">
      </i> Si, eliminar</button>
      <button class="btn btn-danger" (click)="cerrarModalEvaluacion()"><i class="bx bx-error-circle"></i>
        No, cancear</button>
     </div>
    </div>    
  </ng-template>

