import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExamenesService } from 'src/app/service/examenes.service';
import { SidebarMenuComponent } from "../../../../../common/sidebar-menu/sidebar-menu.component";

@Component({
  selector: 'app-actualizar-preguntas',
  standalone: true,
  imports: [CommonModule, FormsModule, SidebarMenuComponent],
  templateUrl: './actualizar-preguntas.component.html',
  styleUrl: './actualizar-preguntas.component.scss',
  providers:[ToastrService]
})
export class ActualizarPreguntasComponent implements OnInit{
  materias: any[] = []
  preguntasPrevias:any[] = []
  filteredPreguntasPrevias: any[] = [];
  preguntasGuardadas:any[] = []
  selectedSubjectId: number | null = null;
  selectedDifficulty: string | null = null;
  selectedQuestionType: string | null = null;
  seHizoPeticion: boolean = false
  cantidadMarcas:number = 0
  quizId: string;
  examenName: string;
  isMobile: boolean

  constructor(private examenesService: ExamenesService, private route: ActivatedRoute,
    private toastrService: ToastrService, private cdr: ChangeDetectorRef){
     this.checkScreenSize()
    }

    toggleExpand(diploma: any) {
      diploma.isExpanded = !diploma.isExpanded;
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.quizId = params['quizId'];
      this.examenName = params['examenName'];
    });
    this.obtenerMateriasSelect()
    this.obtenerPreguntasGuardadas()
  }

       // Usar HostListener para escuchar los cambios de tamaño de la ventana
       @HostListener('window:resize', ['$event'])
       onResize(event: any) {
         this.checkScreenSize();
       }
     
       checkScreenSize() {
         this.isMobile = window.innerWidth < 576;
         console.log('isMobile:', this.isMobile);
         this.cdr.markForCheck(); // Notificar a Angular que revise los cambios
       }

  obtenerMateriasSelect() {
    this.examenesService.obtenerPreguntasTabla().subscribe(
      (response: any[] = []) => {
        console.log(response, 'materias')
        this.materias = response
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  obtenerPreguntasGuardadas() {
    this.examenesService.obtenerPreguntasGuardadas(parseInt(this.quizId)).subscribe(
      (response: any[] = []) => {
        console.log(response, 'preguntas guardadas');
        this.preguntasGuardadas = response;

        // Calcular la suma de todos los marks
        this.cantidadMarcas = response.reduce((total, pregunta) => {
          return total + (pregunta.marks || 0);
        }, 0);
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  onSubjectChange(subjectId: number) {
    this.selectedSubjectId = subjectId;
    console.log('selected', this.selectedSubjectId)
    this.cambiarMateria()
  }

  onDifficultyChange(difficulty: string) {
    this.selectedDifficulty = difficulty || null;
    this.cambiarMateria()
  }

  onQuestionTypeChange(questionType: string) {
    this.selectedQuestionType = questionType || null;
    this.cambiarMateria()
  }

  cambiarMateria() {
    if (this.selectedSubjectId) {
      console.log(this.selectedSubjectId, this.selectedDifficulty, this.selectedQuestionType)
      this.examenesService.cambiarMateria(this.selectedSubjectId, this.selectedDifficulty, this.selectedQuestionType)
        .subscribe(
          (response: any[] = []) => {
            console.log('Preguntas:', response);
            this.seHizoPeticion = true
            this.preguntasPrevias = response
            this.filteredPreguntasPrevias = [...response];
          },
          (error: any) => {
            console.error('Error actualizando materias:', error);
          }
        );
    } else {
      console.log('Debe seleccionar una materia.');
    }
  }

  guardarListaPreguntas() {
    this.examenesService.guardarListaPreguntas(parseInt(this.quizId), this.preguntasGuardadas).subscribe(
      (result) => {
        console.log('se guardo la lista', result)
        this.toastrService.success("Se actualizo la lista de preguntas", "Exito")
      },
      (error: any) => {
        console.log('no se pudo guardar la lista', error)
        this.toastrService.error("No se pudo actualizar la lista", "Error")
      }
    );
  }

  agregarPregunta(pregunta: any) {
    const agregada = this.preguntasGuardadas.filter(item => item.id === pregunta.id);
    if(agregada !== undefined && agregada !== null && agregada.length >= 1 ){
        return;
    }
    this.preguntasGuardadas.push(pregunta);
    if (pregunta.marks) {
      this.cantidadMarcas += pregunta.marks;
    }
}

quitarPregunta(pregunta: any) {
    this.preguntasGuardadas = this.preguntasGuardadas.filter(item => item.id !== pregunta.id)
  if (pregunta.marks) {
    this.cantidadMarcas -= pregunta.marks;
  }
}

eliminarTodasLasPreguntas(){
  this.preguntasGuardadas = []
  this.cantidadMarcas = 0
}

filtrarPreguntas(termino: string) {
  // Convierte el término de búsqueda a minúsculas para una comparación insensible a mayúsculas
  const busqueda = termino.toLowerCase();

  // Filtrar las preguntas previas
  this.filteredPreguntasPrevias = this.preguntasPrevias.filter(preg => {
    const preguntaMatch = preg.questionText.toLowerCase().includes(busqueda);
    const nivelMatch = preg.difficultyLevel.toLowerCase().includes(busqueda) ||
                      this.translateDifficulty(preg.difficultyLevel).toLowerCase().includes(busqueda);
    const tipoMatch = preg.questionType.toLowerCase().includes(busqueda);

    return preguntaMatch || nivelMatch || tipoMatch;
  });
}


translateDifficulty(difficulty: string): string {
  switch (difficulty) {
    case 'easy':
      return 'facil';
    case 'medium':
      return 'medio';
    case 'hard':
      return 'dificil';
    default:
      return difficulty; // Por si acaso se encuentra otro nivel no traducido
  }
}
}
