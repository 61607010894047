import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ExamenesAdminComponent } from "../examenes-admin.component";
import { SidebarMenuComponent } from "../../../../common/sidebar-menu/sidebar-menu.component";
import { ExamenesService } from 'src/app/service/examenes.service';
import { DynamicModalComponent } from "../../../../common/dynamic-modal/dynamic-modal.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-instrucciones',
  standalone: true,
  imports: [ExamenesAdminComponent, SidebarMenuComponent, DynamicModalComponent],
  templateUrl: './instrucciones.component.html',
  styleUrl: './instrucciones.component.scss',
  providers:[BsModalService, ToastrService]
})
export class InstruccionesComponent implements OnInit{
  columns = [
    { key: 'title', title: 'Titulo', accordionKey:true },
    { key: 'content', title: 'Contenido' },
    { key: 'actions', title: 'Acciones', type: 'actions', buttonOne: null, buttonTwo: 'Editar'
      , buttonThree: null, buttonFour: null}
  ];

  instrucciones:any[] = []
  originalInstrucciones:any[] = []
  searchTerm:string = ''
  currentPage:number =1

  //MODAL
  modalFields: any[] = [];
  initialData: any = null;
  modalTitle = '';
  @ViewChild('dynamicModal') dynamicModalTemplate: TemplateRef<any>;
  idInstruccionesGuardar:number
  private modalRef: BsModalRef
  saving: boolean = false

  constructor(private examenesService: ExamenesService, private modalService: BsModalService,
    private toastrService: ToastrService){

  }
  ngOnInit(): void {
    this.obtenerInstruccionesGenerales()
  }

  obtenerInstruccionesGenerales() {
    this.examenesService.obtenerInstruccionesGenerales().subscribe(
      (response: any[] = []) => {
        console.log(response, 'inst')
        this.instrucciones = response
        this.originalInstrucciones = [...response]
      },
      (error: any) => {
        console.error('Error obteniendo materias', error);
      }
    );
  }

  filterInstrucciones(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm = input.value.toLowerCase();

    this.instrucciones = this.originalInstrucciones.filter(inst =>
      inst.title.toLowerCase().includes(this.searchTerm)
    );
    this.currentPage = 1;
  }

  guardarInstrucciones(data: any) {
    this.saving = true
    const request:any ={
      title:data.titulo,
      content:data.contenido,
      id:this.idInstruccionesGuardar? this.idInstruccionesGuardar : null,
    }
    this.examenesService.guardarInstrucciones(request)
    .pipe(catchError(error => {
        this.saving = false
        console.error('Error obteniendo materias', error);
        this.toastrService.error("No se pudieron guardar las instrucciones", "Error")
        return throwError(error)
    }))
    .subscribe((result: any) => {
        this.saving = false
        console.log(result)
        this.toastrService.success("Instrucciones guardadas correctamente!", "Exito")
        this.obtenerInstruccionesGenerales()
        this.cerrarModal()
      }
    );
  }

  abrirModal(datos: any) {
    this.idInstruccionesGuardar = datos ? datos.id : null
    this.modalTitle = datos ? 'Editar Instrucciones' : 'Agregar Instrucciones';
    this.modalFields = [
      {
        name: 'titulo', type: 'text', label: 'Titulo*',
        required: true, errorMessage: 'El titulo es obligatorio.', value: datos ? datos.title : null,
        onlyNumbers: false, characterLimit: 50, characterMin: 5
      },
      {
        name: 'contenido', type: 'textarea', label: 'Contenido',
        required: false, value: datos ? datos.content : null,
        onlyNumbers: false, characterMin: 15
      }
    ];
    this.initialData = datos ? this.modalFields : null;

    this.modalRef = this.modalService.show(this.dynamicModalTemplate,  {backdrop:'static'});
  }

  cerrarModal() {
    this.modalRef.hide()
  }
}
