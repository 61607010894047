import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { ExamenesService } from 'src/app/service/examenes.service';

@Component({
  selector: 'app-reporte-examenes',
  standalone: true,
  imports: [],
  templateUrl: './reporte-examenes.component.html',
  styleUrl: './reporte-examenes.component.scss'
})
export class ReporteExamenesComponent implements OnInit{
  nombreUsuario: string
  email:string
  idUser: number;
  estadisticasPorExamen: any[] = []
  estadisticasHistoria: any[] = []

  constructor(private route: ActivatedRoute, private examenesService: ExamenesService,
    private router: Router){
  }

  ngOnInit(): void { 
    Chart.register(...registerables);
    this.route.paramMap.subscribe(params => {
      this.idUser = Number(params.get('iduser'));
      this.nombreUsuario = (params.get('nameuser'));
      this.email = (params.get('email'));
    });
    this.obtenerEstadisticasPorExamen()
    this.obtenerEstadisticasHistoria()
  }

  irAMisEstadisticasPorExamen() {
    const idUser = this.idUser
    const nombreUsuario = this.nombreUsuario || 'defaultUser'; 
    const view = 'examen'
    this.router.navigate(['/mis-estadisticas', idUser, nombreUsuario, view]);
  }

  irAMisEstadisticasHistoria() {
    const idUser = this.idUser
    const nombreUsuario = this.nombreUsuario || 'defaultUser'; 
    const view = 'historia'
    this.router.navigate(['/mis-estadisticas', idUser, nombreUsuario, view]);
  }

  obtenerEstadisticasPorExamen() {
    this.examenesService.obtenerEstadisticasPorExamen(this.idUser).subscribe((data: any) => {
      this.estadisticasPorExamen = data.sort((a: any, b: any) => a.title.localeCompare(b.title));
      this.initRendimientoPorExamenChart();  // Inicializa el gráfico después de cargar los datos
    });
  }

  obtenerEstadisticasHistoria() {
    this.examenesService.obtenerEstadisticasHistoria(this.idUser).subscribe((data: any) => {
      this.estadisticasHistoria = data.map((item: any) => ({
        aciertos: JSON.parse(item.correctAnswers).length,
        errores: JSON.parse(item.wrongAnswers).length,
        noRespondidas: item.totalQuestions - JSON.parse(item.correctAnswers).length - JSON.parse(item.wrongAnswers).length,
      }));
      this.initRendimientoGeneralChart();  // Inicializa el gráfico después de cargar los datos
    });
  }

  // Gráfico Circular: Rendimiento General
  initRendimientoGeneralChart() {
    const aciertos = this.estadisticasHistoria.reduce((acc, item) => acc + item.aciertos, 0);
    const errores = this.estadisticasHistoria.reduce((acc, item) => acc + item.errores, 0);
    const noRespondidas = this.estadisticasHistoria.reduce((acc, item) => acc + item.noRespondidas, 0);

    new Chart("rendimientoGeneralChart", {
      type: 'doughnut',
      data: {
        labels: ['Correctas', 'Erróneas', 'No Respondidas'],
        datasets: [{
          data: [aciertos, errores, noRespondidas],
          backgroundColor: ['#4CAF50', '#F44336', '#FFC107']
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: { position: 'top' },
        }
      }
    });
  }

  // Gráfico de Barras: Rendimiento por Examen
  initRendimientoPorExamenChart() {
    const labels = this.estadisticasPorExamen.map((examen: any) => examen.title);
    const percentages = this.estadisticasPorExamen.map((examen: any) => examen.percentage);

    new Chart("rendimientoPorExamenChart", {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Porcentaje de Rendimiento',
          data: percentages,
          backgroundColor: '#4CAF50'
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
            ticks: { stepSize: 10 }
          }
        },
        plugins: {
          legend: { display: false },
        }
      }
    });
  }
}
