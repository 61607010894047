<app-sidebar-menu></app-sidebar-menu>
<app-examenes-admin
  [columns]="columns"
  [data]="examenes"
  title="Cuestionarios"
  buttonText="Nuevo Examen"
  (onOpenModal)="abrirModal($event)"
  (onPrimaryAction)="irAActualizarPreguntas($event)"
  (onFilterChange)="filterExamenes($event)">
</app-examenes-admin>

<ng-template #dynamicModal>
    <app-dynamic-modal
      [modalTitle]="modalTitle"
      [fields]="modalFields"
      [initialData]="initialData"
      [saving]="saving"
      (formSubmit)="guardarExamen($event)"
      (closeModal)="cerrarModal()"
      (selectChange)="handleQuestionTypeChange($event)">
    </app-dynamic-modal>
</ng-template>
